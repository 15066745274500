export const apiPath = process.env.REACT_APP_STAGE === 'prod' ? 'https://api-3.coursio.com/' : 'https://t-api.s.coursio.com/';
export const basePath = '/';
// api
export const paymentApi = () => `${apiPath}api/payment`;
export const transactionsApi = () => `${apiPath}api/payment/incoming`;
export const storeApi = () => `${apiPath}api/store`;
export const guestApi = () => `${apiPath}api/guest`;
export const encodedApi = () => `${apiPath}api/upload/encoded`;
export const plainApi = () => `${apiPath}api/upload/plain`;
export const fileApi = () => `${apiPath}api/file`;
export const courseApi = () => `${apiPath}api/course`;
export const coursePackageApi = () => `${apiPath}api/course-package`;
export const discountApi = () => `${apiPath}api/discount`;
export const dashboardApi = () => `${apiPath}api/dashboard`;
export const notesApi = () => `${apiPath}api/note`;
export const userApi = () => `${apiPath}api/user`;
export const groupApi = () => `${apiPath}api/circle`;
export const inviteApi = () => `${apiPath}api/invite`;
export const invitationApi = () => `${apiPath}api/invitation`;
export const notificationsApi = () => `${apiPath}api/notifications`;
export const settingsApi = () => `${apiPath}api/settings`;
export const statisticsApi = () => `${apiPath}api/statistics`;
export const searchApi = () => `${apiPath}api/search`;
export const quizApi = () => `${apiPath}api/quiz`;
export const diplomaApi = () => `${apiPath}api/diploma`;
export const sectionApi = () => `${apiPath}api/section`;
export const pageApi = () => `${apiPath}api/page`;
export const authApi = () => `${apiPath}api/auth`;
export const taskApi = () => `${apiPath}api/task`;
export const taskResultsApi = () => `${apiPath}api/task-result`;
export const accessApi = () => `${apiPath}api/access`;
export const mrWolfUserApi = () => `${apiPath}api/mr.wolf-user`;
export const mrWolfUcpApi = () => `${apiPath}api/mr.wolf-ucp`;
export const selfServiceApi = () => `${apiPath}api/self-service`;
export const postApi = () => `${apiPath}api/post`;
export const contentApi = () => `${apiPath}api/content`;

import React from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import CourseImageWrapper from './CourseImageWrapper';
import CourseCardWrapper from './CourseCardWrapper';
import CourseInfoWrapper from './CourseInfoWrapper';
import { Flex, Icon, Typo, Button, Figure, theme } from '..';
import { priceFormater } from '../../helpers/formatNumbers';

const ActionsWrapper = styled.div`
  display: flex;
  width:100%;
  flex-direction:column;
  align-items: flex-start;
  gap: 8px;
`;

const BundleTag = styled.div`
  background-color: ${(props) => props.theme.colors.MARKER_LIGHT};
  padding:0.5rem;
  border-radius: 4px;
`
const BudleTagsWrapper = styled.div`
  position: absolute;
  top: 8px;
  display:flex;
  flex-direction:column;
`
const ImagePlaceholder = styled.div`
  background-color: ${(props) => props.theme.colors.NEUTRAL_20};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const CourseCard = ({
  courseInfo,
  goToCoursePage,
  addToCart,
  showProgress,
  showButtons,
  inGrid,
  showPackageInfo,
  hideVAT,
  storeTheme
}) => {
  const { t } = useTranslation();

  const { coverImage, price, currency, coverUrl } = courseInfo
  const imageUrl = !coverImage ? coverUrl : Array.isArray(coverImage) ? coverImage[0]?.url : coverImage?.url

  const isPackage = courseInfo?.type === 2
  const packageSize = courseInfo?.courses?.length

  const handleBuy = (e) => {
    e.stopPropagation()
    addToCart(courseInfo)
  }

  const clickOnCard = () => {
    if (!showPackageInfo) goToCoursePage(courseInfo, courseInfo.type === 2)
    else showPackageInfo(courseInfo)
  }

  const handleShowPackageInfo = (e) => {
    e.stopPropagation()
    showPackageInfo(courseInfo)
  }

  return (
    <CourseCardWrapper onClick={clickOnCard} inGrid={inGrid}>
      <CourseImageWrapper >
        <Figure.Rectangle>
          {!imageUrl
            ? <ImagePlaceholder />
            : <img src={imageUrl} alt="CourseImage" loading='lazy' />}
        </Figure.Rectangle>
      </CourseImageWrapper>
      <CourseInfoWrapper
        course={courseInfo}
        showProgress={showProgress}
        showButtons
        ownerAvatar={storeTheme?.logoUrl}
        ownerColor={storeTheme?.color}
        extraPadding={showButtons && !!isPackage}
      >
        <BudleTagsWrapper>
          {showButtons && !!isPackage && <BundleTag>
            <Flex gap='5'>
              <Icon.Package size='x-small' color={theme.colors.MARKER} />
              <Typo.Info color={theme.colors.MARKER} >{t('webshop.packageCoursesCount', { number: packageSize })}</Typo.Info>
            </Flex>
          </BundleTag>}
        </BudleTagsWrapper>
        {showButtons && <ActionsWrapper>
          <Button $asText onClick={clickOnCard} >
            {t('globals.moreInfo')}
          </Button>
          <Flex justify='space-between' width='100%' >
            <Flex direction='column' align='flex-start'>
              <Typo.TextTitle>{priceFormater(price, hideVAT, currency)}</Typo.TextTitle>
              {hideVAT && <Typo.Info>{t('globals.priceExcVat')}</Typo.Info>}
            </Flex>
            {+price?.gross > 0 && <Button $variant='action' onClick={handleBuy} >
              {t('webshop.buy')}
            </Button>}
          </Flex>
        </ActionsWrapper>
        }
        {showPackageInfo && <Flex justify='end'>
          <Button $themeColor={storeTheme.color} onClick={handleShowPackageInfo} $variant='themed'>{t('globals.moreInfo')}</Button>
        </Flex>}
      </CourseInfoWrapper>
    </CourseCardWrapper >
  );
};

export default CourseCard;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Flex, Textinput, Toggler, Typo, Modal, SearchSelect } from '../../../ui';
import { fetchDiscountById, selectDiscountById } from '../discountsSlice';
import { fetchEditableCourses, selectCourses, fetchEditablePackages, selectPackages } from '../../courses/coursesSlice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { selectLocalization } from '../../auth/authSlice';

const Wrapper = styled.div`
  .MuiPaper-root {
    width: 100%;
  }
`;

const DiscountEdit = ({ open, closeDialog, discountId, onSave }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const info = useSelector((state) => selectDiscountById(state, discountId));
  const discountLocalData = useRef({});
  const locale = useSelector(selectLocalization)

  const allCourses = useSelector(selectCourses)
  const allPackages = useSelector(selectPackages);

  const [discountType, setDiscountType] = useState(1)
  const [isActive, setIsActive] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [coursesList, setCoursesList] = useState([])
  const [packagesList, setPackagesList] = useState([])

  useEffect(() => {
    if (open) {
      dispatch(fetchEditableCourses({}))
      dispatch(fetchEditablePackages())
    }
  }, [dispatch, open])

  useEffect(() => {
    if (discountId) {
      dispatch(fetchDiscountById({ discountId }));
    } else {
      setEndDate(null);
      setStartDate(null);
      setCoursesList([]);
      setPackagesList([]);
    }
  }, [dispatch, discountId])

  useEffect(() => {
    if (info?.hasOwnProperty('pseudoId')) {
      setDiscountType(info?.type)
      setIsActive(info.active)
      const initDate = info.startDate === '0000-00-00' ? null : info.startDate
      setStartDate(initDate)
      const finishDate = info.endDate === '0000-00-00' ? null : info.endDate
      setEndDate(finishDate)

      let packages = []
      let courses = []
      if (info?.objects?.length) {
        courses = info.objects.filter(object => object.type === '1');
        packages = info.objects.filter(object => object.type === '2');
      }

      setPackagesList(packages)
      setCoursesList(courses)
    }
  }, [info])

  const handleTypeChange = useCallback((checked) => {
    const type = !!checked ? 2 : 1
    setDiscountType(type)
  }, [])

  const handleActiveChange = useCallback((e) => {
    setIsActive(e.target.checked)
  }, [])

  const handleSave = useCallback(() => {
    const {
      discountName, discountValue,
      startDate, endDate, usageLimit, activateDiscount
    } = discountLocalData.current;
    const objects = [];

    coursesList.forEach(pickedCourse => objects.push({ ...pickedCourse, type: 1 }));
    packagesList.forEach(pickedPackage => objects.push({ ...pickedPackage, type: 2 }));

    const discountData = {
      type: discountType,
      code: discountName.value,
      discount: discountValue.value,
      maxCount: usageLimit.value,
      active: activateDiscount.checked,
      objects,
    };

    if (startDate.value.length > 0) {
      discountData['startDate'] = dayjs(startDate.value).format('YYYY-MM-DD')
    }
    if (endDate.value.length > 0) {
      discountData['endDate'] = dayjs(endDate.value).format('YYYY-MM-DD')
    }

    if (discountId) {
      onSave({ ...discountData, id: discountId });
    } else {
      onSave(discountData, true)
    }
    closeDialog();
  }, [discountId, closeDialog, onSave, packagesList, coursesList, discountType])

  return (
    <Modal
      open={open}
      onClose={closeDialog}
      title={t('settings.editDiscount')}
      showActions
      onAccept={handleSave}
      acceptLabel={t('globals.save')}
    >
      <Wrapper>
        <Flex gap='20' direction='column' align='flex-start' width='100%'>
          <Flex direction='column' align='flex-start'>
            <Typo.Text>{t('settings.discountType')}</Typo.Text>
            <Toggler
              leftText={t('settings.percentage')}
              rightText={t('settings.absolute')}
              onClick={handleTypeChange}
              checked={discountType !== 1} />
          </Flex>
          <Flex justify={'space-between'} gap='20'>
            <div>
              <Typo.Text>{t('settings.codeName')}</Typo.Text>
              <Textinput
                innerRef={el => discountLocalData.current['discountName'] = el}
                defaultValue={info?.code}
                propName={'discountName'}
              />
            </div>
            <div>
              <Typo.Text >{discountType === 1 ? t('settings.percentage') : t('settings.absolute')}</Typo.Text>
              <Textinput
                innerRef={el => discountLocalData.current['discountValue'] = el}
                defaultValue={info?.discount}
                propName={'discountValue'}
              />
            </div>
          </Flex>

          <Flex direction='column' align='flex-start' gap='10'>
            <Typo.Text>{t('settings.validBetween')}</Typo.Text>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              <Flex gap='20'>
                <DatePicker
                  inputRef={el => discountLocalData.current['startDate'] = el}
                  inputFormat={'YYYY-MM-DD'}
                  label={t('globals.startDate')}
                  value={startDate ? dayjs(startDate) : null}
                  onChange={(newValue) => {
                    setStartDate(newValue)
                  }}
                />
                <DatePicker
                  inputRef={el => discountLocalData.current['endDate'] = el}
                  inputFormat={'YYYY-MM-DD'}
                  label={t('globals.endDate')}
                  value={endDate ? dayjs(endDate) : null}
                  onChange={(newValue) => {
                    setEndDate(newValue)
                  }}
                />
              </Flex>
            </LocalizationProvider>
          </Flex>

          <div>
            <Typo.Text>{t('settings.usageLimit')}</Typo.Text>
            <Textinput
              innerRef={el => discountLocalData.current['usageLimit'] = el}
              propName={'usageLimit'}
              defaultValue={info?.maxCount}
              variant='standard'
              type='number'
            />
          </div>

          <div>
            <Typo.Text>{t('settings.activateDiscount')}</Typo.Text>
            <Flex >
              <Switch
                inputRef={el => discountLocalData.current['activateDiscount'] = el}
                checked={isActive}
                onChange={handleActiveChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typo.Info>{isActive ? t('settings.active') : t('globals.disabled')}</Typo.Info>
            </Flex>
          </div>

          {!!allCourses?.length && <Flex width='100%'>
            <SearchSelect
              multiple
              setValue={setCoursesList}
              selectedOptions={coursesList}
              id='courses'
              label={t('settings.discountCourses')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={allCourses.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
            />
          </Flex>}

          {!!allPackages?.length && <Flex width='100%'>
            <SearchSelect
              multiple
              setValue={setPackagesList}
              selectedOptions={packagesList}
              id='bundles'
              label={t('settings.discountPackages')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={allPackages.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
            />
          </Flex>}
        </Flex>
      </Wrapper>
    </Modal>
  );
};

export default DiscountEdit;

import React, { useEffect, useState } from 'react';
import { Button, Typo, theme } from '../../../../ui';
import ResultsScreen from './ResultsScreen';

const StartScreen = ({ quizSettings, pageInfo, handleStartQuiz, hasPrevAnswers, lastQuizData, canShowResults, themeColor }) => {

  const [showResults, setShowResults] = useState(false)

  useEffect(() => {
    setShowResults(!quizSettings.repeatable && hasPrevAnswers)
  }, [hasPrevAnswers, quizSettings.repeatable])

  const handleShowResults = () =>{
    setShowResults(true)
  }

  return ( 
    showResults ? 
      <ResultsScreen 
        pageInfo={pageInfo} 
        canShowResults={canShowResults} 
        lastQuiz={lastQuizData}
        themeColor={themeColor}
        handleStartQuiz={quizSettings.repeatable && handleStartQuiz}
        quizSettings={quizSettings} /> 
      : <>
        <Typo.BigText>{quizSettings?.welcomeText}</Typo.BigText>
        <Typo.MediumText>{quizSettings?.descriptionText}</Typo.MediumText>
        {(quizSettings.repeatable || !hasPrevAnswers) && <Button $variant='customized' $bgcolor={themeColor} onClick={handleStartQuiz}>Start Quiz</Button>}
        {hasPrevAnswers && <Button $variant='customized' $bgcolor={themeColor} onClick={handleShowResults}>Previous Result</Button>}
      </>
  )
};

export default StartScreen;

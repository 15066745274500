import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import CtaButton from './CtaButton';
import { Typo, Flex, Button } from '../../../../ui';
import { getDarkerThemeColor } from '../../../../ui/theme/colors';
import { CONTENT_MINI, media } from '../../../../utils/media';

const Wrapper = styled.div`
  display:flex;
  margin: auto;
  background-color:${({$themeColor})=>$themeColor};
  justify-content: center;
  width:100%;
`

const Content = styled.div`
  display:flex;
  max-width: ${() => CONTENT_MINI}px;
  padding: 25px 0;
  align-items: center;
  width: 100%;
  gap: 9px;
  justify-content: space-between;

  ${media.lessThan('tablet')} {
    padding: 25px 1.5rem; 
  }

  ${media.lessThan('phablet')} {
    flex-direction: column;
    gap: 20px;
    padding: 15px 1.5rem; 
  }

`

const CTABanner = ({themeColor, courseId, goToAllCourses, buyButton, info, addItemHandler}) => {
  const { t } = useTranslation();
  const darkColor = getDarkerThemeColor(themeColor)
  return (
    <Wrapper $themeColor={darkColor} >
      <Content>
        <Flex direction='column' align='flex-start'>
          <Typo.ContentSmallHeader $themeColor={darkColor} >{info.title}</Typo.ContentSmallHeader>
          <Typo.Text $themeColor={darkColor}>{info.description}</Typo.Text>
        </Flex>
        {buyButton ? <CtaButton addItemHandler={addItemHandler} courseId={courseId} inverted={true} $themeColor={themeColor} $bgColor={darkColor}/>
        : <Button $themeColor={themeColor} $variant='themed' onClick={goToAllCourses} >
            {t('webshop.course.allCourses')}
          </Button>
        }
      </Content>
    </Wrapper>
  );
};

export default CTABanner;

import React from 'react';
import { Loading } from '../../../../ui';

const ModuleComponents = {
  text: React.lazy(() => import('../TextModule')),
  image: React.lazy(() => import('../ImageModule')),
  video: React.lazy(() => import('../VideoModule')),
  discussion: React.lazy(() => import('../DiscussionModule')),
  audio: React.lazy(() => import('../AudioModule')),
  slideshow: React.lazy(() => import('../SlideshowModule')),
  task: React.lazy(() => import('../TaskModule')),
  webinar: React.lazy(() => import('../Webinar')),
  embed: React.lazy(() => import('../EmbedModule')),
  download: React.lazy(() => import('../DownloadModule')),
};

const ModuleSwitcher = ({ module, columns, themeColor }) => {
  const ModuleComponent = ModuleComponents[module.type.name];

  return (
    <React.Suspense fallback={<Loading themeColor={themeColor} />}>
      <ModuleComponent data={module.data} module={module} split50={columns===2} themeColor={themeColor}/>
    </React.Suspense>
  );
};

export default ModuleSwitcher;

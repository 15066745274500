import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuWrapper } from '../../ui';
import { useSelector } from 'react-redux';
import { selectFeatures } from '../settings/settingsSlice';
import NewBadge from '../../ui/NewBadge';

const Profile = () => {

  const { t } = useTranslation();

  const features = useSelector(selectFeatures)
  const [navigationItems, setNavigationItems] = useState([])

  useEffect(() => {
    const items = [
      { label: t('profile.personalInfo'), 'value': 'info' },
      features.diplomaDownload && { label: t('globals.diploma'), 'value': 'diplomas', icon:<NewBadge label={t('globals.new')} /> },
      { label: t('globals.password'), 'value': 'password' },
      { label: t('globals.notifications'), 'value': 'notifications' },
      { label: t('globals.gift'), 'value': 'gift' },
    ].filter(Boolean)
    setNavigationItems(items)

  }, [features, setNavigationItems, t])

  return (
    <>
      <SubMenuWrapper>
        {!!navigationItems.length && <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />}
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Profile;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStore, selectDiscountCode, selectStoreName, selectUserTheme } from '../../globalSlice';
import styled from 'styled-components'
import { Button, Flex, Symbols, Textinput, Typo } from '../../../../ui';
import theme from '../../../../ui/theme';
import { useTranslation } from 'react-i18next';
import { resetDiscountValidation, selectIsCodeValid, validateDiscount } from "../../../discounts/discountsSlice";

const DiscountWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1rem;
  width:100%;
  border-top: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
`;

const ButtonStyled = styled(Button)`
  width:50%;
`

const Discount = ({ clearDiscount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userTheme = useSelector(selectUserTheme);

  const storeName = useSelector(selectStoreName);
  const discountCode = useSelector(selectDiscountCode);
  const isValidDiscount = useSelector(selectIsCodeValid);
  const [typedDiscount, setCurrentDiscount] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isValidDiscount && discountCode === typedDiscount) setCurrentDiscount('');
  }, [isValidDiscount, typedDiscount, discountCode])

  useEffect(() => {
    if (!typedDiscount?.length) dispatch(resetDiscountValidation());
  }, [typedDiscount, dispatch])

  const setDiscount = useCallback((e) => {
    e.preventDefault();
    dispatch(validateDiscount(typedDiscount)).then(res => {
      if (res.payload) {
        dispatch(fetchStore({ store: storeName, discountCode: typedDiscount }));
      }
    })
  }, [dispatch, storeName, typedDiscount]);

  const isDiscountError = !isValidDiscount && !!typedDiscount?.length;

  const handleDiscountChange = useCallback((e) => {
    setCurrentDiscount(e.target.value);
    setDisabled(!e.target.value);
    if (isValidDiscount) {
      dispatch(resetDiscountValidation());
    }
  }, [dispatch, isValidDiscount]);

  useEffect(() => {
    if (typedDiscount.length === 0 && isDiscountError) clearDiscount();
  }, [typedDiscount, clearDiscount, isDiscountError])

  return (
    <DiscountWrapper className={isDiscountError ? 'error' : ''} onSubmit={setDiscount}>
      <Flex gap='10'>
        <Symbols.Money size='medium' $withBgColor={theme.colors.PRIMARY_LIGHT} color={theme.colors.PRIMARY} />
        <Typo.Text>{t('webshop.checkout.discountQuestion')}</Typo.Text>
      </Flex>
      <Flex justify='space-between' gap='18' align='baseline' >
        <Textinput
          $maxwidth={'60%'}
          placeholder={t('discount.code')}
          onChange={handleDiscountChange}
          value={typedDiscount}
          error={!!isDiscountError}
          helperText={isDiscountError ? t('webshop.checkout.invalidDiscount') : ''}
        />
        <ButtonStyled $variant='themed' $themeColor={userTheme.color} disabled={disabled}>{t('webshop.checkout.activateCode')}</ButtonStyled>
      </Flex>
    </DiscountWrapper>
  )
};

export default Discount;

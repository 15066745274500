import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typo, Flex, Figure, Modal } from '../../../../ui';
import VideoUploader from '../../../media/uploaders/VideoUploader';
import ImageUploader from '../../../media/uploaders/ImageUploader';

const CoverMediaLayout = ({ open, info, addToPreview, onClose}) => {
  const { t } = useTranslation();

  const [coverMedia, setCoverMedia] = useState();

  useEffect(() =>  setCoverMedia(info), [info])

  const deleteVideo = useCallback(()=> {
    setCoverMedia({...coverMedia, video:null})
  },[coverMedia])

  const saveVideo = useCallback((data)=>{
    setCoverMedia({...coverMedia, video:data})
  },[coverMedia])

  const saveImage = useCallback((data)=>{
    if (data?.id === -1)
      setCoverMedia({...coverMedia, image:null})
    else setCoverMedia({...coverMedia, image:data})
  },[coverMedia])

  const onAccept = useCallback(() => {
    addToPreview(coverMedia)
  }, [coverMedia, addToPreview])

  return (
    <Modal
      open={open} 
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel={t('webshop.addPreview')}
        
    >
      <Flex direction='column' width='100%' align='flex-start' gap='40'>
        <Typo.SectionHeader $marginBottom='10'>{t('webshop.build.coverVideoTitle')}</Typo.SectionHeader>
        <Flex direction='column' gap='10' align='flex-start'>
          <Typo.Text>{t('webshop.build.coverVideoDescription_1')}</Typo.Text>
          <Typo.Info>{t('webshop.build.coverVideoDescription_2')}</Typo.Info>
          <Typo.Info>{t('webshop.build.coverVideoDescription_3')}</Typo.Info>
          <VideoUploader
            saveAction={saveVideo}
            videoToPreview={coverMedia?.video}
            trackDeleted={deleteVideo}
          />
        </Flex>
        <Flex direction='column' gap='10' align='flex-start'>
          <Typo.MediumText>{t('webshop.build.coverImageTitle')}</Typo.MediumText>
          <ImageUploader
            saveByDelete
            type={'single'}
            id={'poster-image'}
            saveAction={saveImage}
            imageToPreview={coverMedia?.image?.url}
          >
            {coverMedia?.image?.url && <Figure.Rectangle><img alt={'imageToPreview'} src={coverMedia?.image?.url} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CoverMediaLayout;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect, Flex, Typo, Modal } from '../../../../ui';

const BuildSuggestedCourse = ({
  allCourses,
  selectedCourse,
  open,
  onClose,
  addToPreview
}) => {
  const { t } = useTranslation();

  const [suggestedCourse, setSuggestedCourse] = useState('');

  useEffect(() => {
    selectedCourse && setSuggestedCourse(selectedCourse)
  }, [selectedCourse])

  const onAccept = useCallback(() => {
    addToPreview(suggestedCourse)
  }, [suggestedCourse, addToPreview]);

  return (
  <Modal
    open={open}
    showActions={true}
    onClose={onClose}
    onCancel={onClose}
    onAccept={onAccept}
    acceptLabel={t('webshop.addPreview')}
  >
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.suggestedCourseTitle')}</Typo.ContentSmallHeader>
      <SearchSelect
        id="promote-course"
        options={allCourses.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
        selectedOptions={suggestedCourse}
        label={t('globals.courses')}
        setValue={setSuggestedCourse}
      />
    </Flex>
  </Modal>
  );
};

export default BuildSuggestedCourse;

import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Button, Flex, Page, Typo, PersonalMessage, theme, Figure, PageHeaderLogo } from '../../ui';
import Loading from '../../ui/Loading';
import { logOut, registrationAndLogin, selectIsLoggedIn, selectUserEmail } from '../auth/authSlice';
import { fetchStore, selectStoreOwner, selectUserTheme } from '../webshop/globalSlice';
import { acceptInvitation, checkInvitation, selectCheckStatus, selectRedeemInvitation } from './invitesSlice';
import { setLoginModal } from '../global/globalSlice';
import RegistrationForm from '../../components/RegistrationForm';
import { media } from '../../utils/media';
import PageContainerVCenter from '../../ui/Page/PageContainerVCenter';
import { Trans, useTranslation } from 'react-i18next';
import { STATUS_LOADING } from '../../utils/constants';

const InvitationWrapper = styled.div`
  display:flex; 
  flex-direction:row-reverse;
  align-items:flex-start;
  gap:80px;
  width:100%;
  justify-content:space-between;
  background-color:${theme.colors.NEUTRAL_0};
  padding:40px;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    padding:20px;
  }
`

const ErrorWrapper = styled.div`
  display:flex; 
  flex-direction:column;
  gap:100px;
  width:70%;
  align-items:flex-start;
  background-color:${theme.colors.NEUTRAL_0};
  padding:40px;
  ${media.lessThan('phablet')} {
    width:100%;
    padding:20px;
  }
`


const AcceptInvitation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { hash } = useParams();

  const invitationFeedback = useSelector(selectRedeemInvitation)
  const userEmail = useSelector(selectUserEmail)
  const loggedIn = useSelector(selectIsLoggedIn)
  const webshopOwner = useSelector(selectStoreOwner)
  const webshopTheme = useSelector(selectUserTheme)
  const [errorMessage, setErrorMessage] = useState(null);
  const checkInvStatus = useSelector(selectCheckStatus);
  const { expired, course, used, usedByMe, limit, email, count, errMsg } = invitationFeedback;

  useEffect(() => {
    hash && dispatch(checkInvitation({ hash })).then(action => {
      const ownerInfo = action.payload && action.payload[0]?.owner
      ownerInfo && dispatch(fetchStore({ store: ownerInfo.username }))
    })
  }, [hash, dispatch])

  const handleRequestNewInvitation = useCallback(() => {
    const ownerEmail = invitationFeedback.owner.email;
    document.location = `mailto: ${ownerEmail}`;
  }, [invitationFeedback])

  const handleLogin = () => {
    loggedIn && dispatch(logOut())
    dispatch(setLoginModal(true))
  }

  const handleGoToStartPage = () => {
    navigate(`/dashboard`)
  }

  const handleAccept = () => {
    dispatch(acceptInvitation({ hash })).then(action => {
      const navigatePath = webshopOwner.username ? `/course/${webshopOwner.username}/${course.slug}` : '/dashboard';

      !action.payload.errorCode ? navigate(navigatePath) : setErrorMessage(action.payload.response.errMsg)
    })
  }

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        {errMsg || errorMessage ?
          <ErrorWrapper >
            <Typo.PageHeader>{t('globals.invitation')}</Typo.PageHeader>
            <PersonalMessage >
              <Typo.BigText>{errMsg || errorMessage}</Typo.BigText>
            </PersonalMessage >
            <Flex width='100%' justify='center'>{loggedIn ? <Button onClick={handleGoToStartPage}>{t('invites.goToStart')}</Button> : <Button onClick={handleLogin}>{t('invites.login')}</Button>}</Flex>
          </ErrorWrapper>
          : <InvitationWrapper >
            {checkInvStatus === STATUS_LOADING ? <Flex width='100%' justify='center'><Loading /></Flex>
              : <>
              <Flex width='200px' breakPoint={{ size: 'phablet', value: 'width:150px; align-self:center;' }}>
                {webshopTheme.logoUrl && <Figure.Circle $objectfit='contain' shadow background={theme.colors.NEUTRAL_0}>
                  <img src={webshopTheme.logoUrl} alt={webshopOwner.username} loading='lazy' />
                </Figure.Circle>}
              </Flex>
              <Flex direction='column' gap='40' align='flex-start' width='100%'>
                {email && email !== 'Not provided' && <>
                  <Typo.PageHeader>{t('globals.invitation')}</Typo.PageHeader>
                  <Flex gap='10' breakPoint={{ size: 'phablet', value: 'flex-direction:column' }}>
                    <Typo.ContentBigHeader>{t('invites.invitationSentTo')}:</Typo.ContentBigHeader>
                    <Typo.BigText>{email}</Typo.BigText>
                  </Flex>
                </>}
                <Flex direction='column' gap='50' align='flex-start' width='100%'>
                  {count === limit && used && (!usedByMe || !loggedIn) && <>
                    <PersonalMessage >
                      <Typo.BigText>{t('invites.alreadyAcceptedInvitation_notLoggedIn')}</Typo.BigText>
                    </PersonalMessage>
                    <Button onClick={handleLogin}>{t('invites.login')}</Button>
                  </>
                  }

                  {count === limit && used && usedByMe && <>
                    <PersonalMessage >
                      <Typo.BigText>{t('invites.alreadyAcceptedInvitation_loggedIn')}</Typo.BigText>
                    </PersonalMessage>
                    <Button onClick={handleGoToStartPage}>{t('invites.goToStart')}</Button>
                  </>
                  }

                  {loggedIn && !expired && !used && (userEmail === email || email === 'Not provided') && <>
                    <PersonalMessage >
                      <Typo.BigText>{t('invites.acceptInvitationBellow')}</Typo.BigText>
                    </PersonalMessage>
                    <Button onClick={handleAccept}>{t('invites.accept')}</Button>
                  </>
                  }

                  {(!loggedIn || (userEmail !== email && email !== 'Not provided')) && !used && !expired &&
                    <>
                      <PersonalMessage>
                        <Typo.BigText>{t('invites.acceptInvitationNeedsAccount')}</Typo.BigText>
                      </PersonalMessage>
                      {course && <div>
                        <Typo.TextTitle>{t('globals.invitation')}</Typo.TextTitle>
                        <Typo.MediumText>
                          <Trans t={t} i18nKey={'registration.afterRegister'} values={{ courseName: course.name }} components={{bold: <strong />}} />
                        </Typo.MediumText>
                      </div>}
                      <RegistrationForm email={email} handleLogin={handleLogin} createAccountCall={registrationAndLogin} />
                    </>
                  }

                  {expired && !used && <>
                    <PersonalMessage >
                      <Typo.BigText>{t('invites.expiredInvitation')}</Typo.BigText>
                      <Typo.Text>{t('invites.newInvitationContactOwner')}</Typo.Text>
                    </PersonalMessage >

                    <Flex gap='40'>
                      <Button onClick={handleRequestNewInvitation}>{t('invites.requestNew')}</Button>
                      {loggedIn && userEmail === email ? <Button onClick={handleGoToStartPage} >{t('invites.goToStart')}</Button> : <Button onClick={handleLogin}>{t('invites.login')}</Button>}
                    </Flex>
                  </>}

                </Flex>
              </Flex>
            </>}
          </InvitationWrapper>}
      </PageContainerVCenter>
    </Page >

  );
}

export default AcceptInvitation;

import { theme } from "..";

const colors = {
  COURSIO: '#00c9cc',
  COURSIO_LIGHT: '#E9FFFF',

  //COURSIO NEW COLOR
  PRIMARY: '#0E6C81',
  PRIMARY_LIGHT: '#CFE2E6',

  //Orange
  SECONDARY: '#FF9A03',
  SECONDARY_LIGHT: '#FFBA35',

  //grays
  NEUTRAL_100: '#000000',
  NEUTRAL_PRIMARY: '#3B3B3B',
  NEUTRAL_60: '#696969',
  NEUTRAL_40: '#C4C4C4',
  NEUTRAL_20: '#D0D0D0',
  NEUTRAL_10: '#E7EBEB',
  NEUTRAL_LIGHT: '#F2F2F2',
  NEUTRAL_0: '#FFFFFF',

  //Purple
  MARKER: '#2A00A1',
  MARKER_LIGHT: '#E4DAFF',
  MARKER_SHADE: '#F3EFFF',

  ACTION: '#E51F3C',
  SUCCESS_GREEN: '#339900',
  ERROR_RED: '#E51F3C',

  // COURSIO NEW VIEW COLORS - rename this correctly

  COURSIO_NEW: '#0E6C80',
  COURSIO_NEW_LIGHT: '#28B3D1',
  COURSIO_NEW_EXTRA: '#E5EEF0'
}

export const USER_THEME_COLORS = {
  creen: { r: 0, g: 201, b: 204 },
  green: { r: 39, g: 174, b: 96 },
  blue: { r: 52, g: 152, b: 219 },
  blue2: { r: 41, g: 128, b: 185 },
  purple: { r: 155, g: 89, b: 182 },
  navyblue: { r: 52, g: 73, b: 94 },
  gray: { r: 127, g: 140, b: 141 },
  red: { r: 192, g: 57, b: 43 },
  lightgreen: { r: 102, g: 215, b: 119 },
  teal: { r: 1, g: 150, b: 149 },
  mustard: { r: 146, g: 144, b: 25 },
  darkblue: { r: 16, g: 84, b: 147 },
  golden: { r: 209, g: 206, b: 98 },
  mango: { r: 249, g: 195, b: 50 },
  pinktaco: { r: 238, g: 124, b: 182 },
  pinkster: { r: 241, g: 141, b: 139 },
  orange: { r: 243, g: 147, b: 49 },
  love: { r: 168, g: 51, b: 81 },
  purplehaze: { r: 128, g: 44, b: 106 },
  raspberry: { r: 237, g: 76, b: 124 },
};

const rgbStringToRgbArray = (color) => {
  const parsedColor = color.replace(/[^\d,]/g, '').split(',');
  return [+parsedColor[0], +parsedColor[1], +parsedColor[2]]
}

const convertThemeToArray = (color) => {
  const themeRgbObject = USER_THEME_COLORS[color]
  return [themeRgbObject.r, themeRgbObject.g, themeRgbObject.b]
}

const luma = (color) =>
  (0.2126 * color[0]) + (0.7152 * color[1]) + (0.0722 * color[2]);

const contrastingColor = (color) =>
  (luma(color) <= 150) ? theme.colors.NEUTRAL_0 : theme.colors.NEUTRAL_PRIMARY;

export const hexToRgb = (color, format) => {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  if (format === 'array') return [r, g, b]
  return { r, g, b }
};

export const rgbToHex = (r, g, b) => {
  /* eslint-disable-next-line */
  return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
};

export const getLighterThemeColor = (themeColor, alpha=0.8) => {
  const color = themeColor?.match('rgb') ? themeColor : getUserThemeColor(themeColor)
  return RGB_Linear_Shade(alpha, color);
}

export const getDarkerThemeColor = (themeColor) => {
  const color = themeColor?.match('rgb') ? themeColor : getUserThemeColor(themeColor)
  return RGB_Linear_Shade(-0.5, color);
}

export const RGB_Linear_Shade = (p, c) => {
  /* eslint-disable-next-line */
  var i = parseInt, r = Math.round, [a, b, c, d] = c.split(","), P = p < 0, t = P ? 0 : 255 * p, P = P ? 1 + p : 1 - p;
  return "rgb" + (d ? "a(" : "(") + r(i(a[3] === "a" ? a.slice(5) : a.slice(4)) * P + t) + "," + r(i(b) * P + t) + "," + r(i(c) * P + t) + (d ? "," + d : ")");
}

export const getUserThemeColor = (themeC, format = 'rgba') => {
  const colorTheme = themeC || 'creen'
  const translatedColor =
    (typeof colorTheme === 'string') ?
      (colorTheme.match('#')) ?
        hexToRgb(colorTheme, format)
        : colorTheme.match('rgb') && format === 'array' ? rgbStringToRgbArray(colorTheme)
          : format === 'array' ? convertThemeToArray(colorTheme) : USER_THEME_COLORS[colorTheme]
      : colorTheme;

  const formated = format === 'rgba'
    ? 'rgb(' + translatedColor.r + ',' + translatedColor.g + ',' + translatedColor.b + ')'
    : translatedColor

  return formated;
}

export const getUserThemeContrastColor = (themeColor) => {
  const color = getUserThemeColor(themeColor, 'array')
  const contrastColor = contrastingColor(color)
  return themeColor ? contrastColor : theme.colors.NEUTRAL_PRIMARY;
}

export default colors;

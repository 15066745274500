import React from 'react';
import styled from 'styled-components'
import MediaPlayer from '../MediaPlayer';
import { CONTENT_STANDARD } from '../../utils/media';
import Figure from '../Figure';
import { getLighterThemeColor, getUserThemeColor } from '../theme/colors';
import { imageChecker } from '../../helpers/imageChecker';

const EmptyCoverMedia = styled.div` 
  background-color: ${(props) => props.$themeColor};
  height: 2.5rem;
`
const ImageWrapper = styled.div` 
  max-width: ${`${CONTENT_STANDARD}px`};
  margin: auto;
`

const CoverSection = styled.section`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.$themeColor};
    backdrop-filter: blur(50px);
  }
`;

const CoverWrapper = styled.div`
  overflow:hidden;
  max-width: ${CONTENT_STANDARD}px;
  width: 100%;
  margin: 0 auto;
`

const CoverMedia = ({
  file,
  videoPreview,
  themeColor,
  thumbnailImage,
  emptyCoverMedia,
  thumbnailId= null
}) => {

  const color = getUserThemeColor(themeColor)
  const colorLight = getLighterThemeColor(themeColor)

  return (
    ((!videoPreview && !file && !imageChecker(thumbnailImage, thumbnailId)) || emptyCoverMedia) ?
      <EmptyCoverMedia $themeColor={color} />
      : <CoverSection $themeColor={color} >
        {imageChecker(thumbnailImage, thumbnailId) && !videoPreview && <ImageWrapper size={CONTENT_STANDARD}><Figure.Rectangle $maxHeight='660px'><img alt={'cover'} src={imageChecker(thumbnailImage, thumbnailId)} loading='lazy' /></Figure.Rectangle></ImageWrapper>}
        {videoPreview && <CoverWrapper>
          <Figure.Video>
            <MediaPlayer
              posterImage={imageChecker(thumbnailImage, thumbnailId)}
              previewContent={videoPreview}
              width="100%"
              height="auto"
              playIconColor={'#ffffffdb'}
              hoverEffectColor={colorLight}
            />
          </Figure.Video>
        </CoverWrapper>}
      </CoverSection>
  );
};

CoverMedia.displayName = 'CoverMedia'

export default CoverMedia;

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter, nanoid,
} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_SUCCEEDED, TOAST_ERROR } from '../../utils/constants';
import { quizApi, sectionApi, pageApi, courseApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import { selectCourseQuiz, selectCourseQuizSettings } from '../course/courseSlice';
import { authStatus, selectAuthToken } from '../auth/authSlice';
import httpClient from "../../services/httpClient";

const errorHandler = (req, res, dispatch, reject) => {
  if (req.status < 200 || req.status >= 300) {
    dispatch(enqueueSnackbar({ key: nanoid(), message: { variant: TOAST_ERROR, text: res.errMsg } }))
    return reject({ errorCode: req.status, response: res })
  }
}
// ----------------- Thunks -----------------------------
export const fetchQuizes = createAsyncThunk('quizes/fetchQuizes', async ({ courseId, userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      courseId,
    },
    method: 'read'
  };

  const res = await httpClient.post(quizApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

//check
export const resetUserQuiz = createAsyncThunk('quizes/resetUserQuiz', async ({ quiz, userId }, { getState, dispatch, rejectWithValue }) => {
  const body = JSON.stringify({
    data: {
      userId, pageId: quiz.id,
    },
    method: 'reset'
  })
  const options = {
    method: 'POST',
    headers: {
      token: selectAuthToken(getState()),
    },
    body,
  };
  const url = quizApi()
  const response = await fetch(url, options);
  const res = await response.json();
  if (response.status < 200 || response.status >= 300) {
    if (response.status === 401) dispatch(authStatus())
    return rejectWithValue({ errorCode: response.status, response: res })
  }
  return { userId, quiz };
})

export const createQuiz = createAsyncThunk('course/createQuiz', async (quizData, { getState, rejectWithValue, dispatch }) => {
  const mainOptions = {
    method: 'POST',
    headers: {
      token: selectAuthToken(getState()),
    },
  }
  // CREATE QUIZ SECTION AT COURSE (SECTION/CREATE)
  const sectionOptions = {
    ...mainOptions,
    body: JSON.stringify({
      data: {
        courseId: quizData.courseId,
        name: 'Quiz',
        type: 'quiz'
      },
      method: 'create'
    }),
  };
  const sectionReq = await fetch(sectionApi(), sectionOptions);
  const sectionResult = await sectionReq.json();

  errorHandler(sectionReq, sectionResult, dispatch, rejectWithValue)
  const sectionId = sectionResult.data.id;

  // CREATE PAGE INSIDE QUIZ SECTION (PAGE/CREATE)
  const pageOptions = {
    ...mainOptions,
    body: JSON.stringify({
      data: {
        sectionId,
        name: quizData.name,
        type: 'quiz'
      },
      method: 'create'
    }),
  };
  const pageReq = await fetch(pageApi(), pageOptions);
  const pageResult = await pageReq.json();

  errorHandler(pageReq, pageResult, dispatch, rejectWithValue)
  const pageId = pageResult.data.id;

  // ENABLE QUIZ FOR COURSE / UPDATE QUIZ PAGE (COURSE/UPDATE)
  const { descriptionText, failText, successText, welcomeText, repeatable, threshold, hideAnswers, quizPages, settings } = quizData;
  const courseOptions = {
    ...mainOptions,
    body: JSON.stringify({
      data: {
        id: quizData.courseId,
        settings: {
          quiz: {
            ...settings.quiz,
            enabled: true,
            pages: {
              ...quizPages,
              [pageId]: {
                descriptionText, failText, successText,
                welcomeText, repeatable, threshold, hideAnswers
              }
            }
          }
        }
      },
      method: 'update'
    }),
  }

  const courseReq = await fetch(courseApi(), courseOptions);
  const courseResult = await courseReq.json();

  errorHandler(courseReq, courseResult, dispatch, rejectWithValue)

  return courseResult.data;
});

export const completeQuiz = createAsyncThunk('quiz/complete', async ({ pageId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      pageId,
    },
    method: 'complete'
  };

  const res = await httpClient.post(quizApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

// ----------------- Reducers -----------------------------

const quizesAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  entities: {},
  ids: [],
}

const quizesSlice = createSlice({
  name: 'quizes',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchQuizes.rejected, (state, action) => {
        state.status = STATUS_FAILED
      })
      .addCase(resetUserQuiz.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;

        const { quiz, userId } = action.payload
        //remove original user from list
        const result = quiz.users.filter(user => user.id !== userId);
        const newQuizContent = { ...quiz, users: result }
        quizesAdapter.upsertOne(state, newQuizContent)
      })
      .addCase(fetchQuizes.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;

        const quizes = action.payload;
        const quizData = []

        //go inside of each quiz
        for (const [id, quiz] of Object.entries(quizes)) {
          const tasks = quiz.tasks
          const tasksInfo = {}
          let usersResults = {}
          let maxPoints = 0;
          let userPoints = {};
          //go inside of each task
          for (const [taskId, task] of Object.entries(tasks)) {
            maxPoints += task.maxPoints

            // check for user results
            const results = task.taskResults
            // need rebuild this on BE, taking data is really hard
            /* eslint-disable-next-line */
            results.map((result) => {
              const user = result.user
              const quizByUser = !!quiz.userdata[user.id] && quiz.userdata[user.id]?.pages && quiz.userdata[user.id]?.pages[id]
              const lastStatistics = quizByUser && quizByUser[quizByUser?.length - 1]
              userPoints[user.id] = userPoints[user.id] ? result.points + userPoints[user.id] : result.points
              return usersResults = {
                ...usersResults,
                [user.id]: {
                  id: user.id,
                  name: user.name,
                  totalPoints: userPoints[user.id],
                  isComplete: quiz.userStats[user.id]?.isCompleted,
                  isLocked: quiz.userdata[user.id]?.locked,
                  statistics: !!quiz.userdata[user.id]?.pages && quiz.userdata[user.id]?.pages[id],
                  status: lastStatistics?.status,
                  score: lastStatistics?.score,
                  endTime: lastStatistics?.endTime,
                  startTime: lastStatistics?.startTime,
                  duration: lastStatistics?.duration,
                  total: lastStatistics?.total,

                  questions: {
                    ...usersResults[user.id]?.questions,
                    [taskId]: {
                      questionId: taskId,
                      value: result.body.value,
                      files: result.files,
                      created: result.created,
                      grade: result.body.value.toString() === task.correctResponse.value.toString() ? 1 : 0,
                      points: result.points
                    },
                  }
                }

              }
            })

            tasksInfo[taskId] = {
              id: taskId,
              name: task.caption.main,
              options: { ...task.caption.items },
              correctOptions: task.correctResponse.value,
              maxPoints: task.maxPoints,
              type: task.type,
            }

          }

          let totalPassed = 0
          /* NOTE: id skipped because not used => [id, user] available. */
          for (const [, user] of Object.entries(usersResults))
            totalPassed += user.status === 'QUIZ_SUCCESS' ? 1 : 0

          /* NOTE: add it quiz by quiz to the redux store: */
          const users = Object.values(usersResults)
          quizData.push({
            id: +id,
            name: quiz.page.name,
            maxPoints,
            totalPassed,
            usersCount: users.length,
            users,
            questions: Object.values(tasksInfo),
          })
        }
        quizesAdapter.setAll(state, quizData)

      })
      .addCase(createQuiz.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
      })
      .addCase(completeQuiz.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.quizResult = action.payload
      })
  }
})

// export const { } = quizesSlice.actions

export default quizesSlice.reducer

// ----------------- Selectors -----------------------------
export const {
  selectAll: selectQuizes,
  selectById: selectQuizById,
} = quizesAdapter.getSelectors((state) => state.quizes)

export const selectQuizResult = (state) => state.quizes.quizResult;

export const selectQuizesInfo = (state) => {

  const quizPages = selectCourseQuizSettings(state)
  const tocQuiz = selectCourseQuiz(state)
  const quizInfo = selectQuizes(state)

  let newQuizInfo = {}

  if (tocQuiz) {

    tocQuiz.forEach((value) => {
      if(!quizPages || !value) return;
      const info = quizInfo?.find(element => element.id === value.id)
      const page = quizPages && quizPages[value.id]
      newQuizInfo[value.id] = {
        ...value,
        ...page,
        ...info

      }
    })

  }

  // if (quizInfo) {

  //   quizInfo.forEach((value) => {
  //     const toc = tocQuiz?.find(element => String(element.id) === value.id)
  //     const page = quizPages && quizPages[value.id]
  //     newQuizInfo[value.id] = {
  //       ...value,
  //       ...page,
  //       ...toc

  //     }
  //   })

  // }

  // if (quizPages) {
  //   for (const [key, value] of Object.entries(quizPages)) {
  //     const info = quizInfo.find(element => String(element.id) === key)
  //     const toc = tocQuiz.find(element => String(element.id) === key)
  //     newQuizInfo[key] = {
  //       ...value,
  //       ...info,
  //       ...toc
  //     }
  //   }
  // }

  return Object.values(newQuizInfo)
}




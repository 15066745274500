import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, theme, IconAsButton } from '../../ui';
import { fetchCourseById, selectCourse } from '../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../hooks/readFromUrls';
import { coursePageNavigation, drawerWidth } from '../../utils/constants';
import { media } from '../../utils/media';
import TocMenu from './components/TocMenu';
import CoursePage from './components/CoursePage';
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeColor } from '../../ui/theme/colors';
import Buttons from '../../ui/Buttons';
import NotesDrawer from './components/NotesDrawer/Index';
import { selectNotesSliderToggle, setNotesSliderToggle } from '../global/globalSlice';
import NotesMobile from './components/NotesDrawer/NotesMobile';
import usePageNavigation from '../../utils/pageNavigation';

const IconAsButtonStyled = styled(IconAsButton)`
  position: absolute !important;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 999;
  background-color: ${(props) => props.backgroundColor} !important;
`;

const NavButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  height: ${coursePageNavigation}px;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor} !important;
  width: calc(100% - ${drawerWidth}px);

  ${media.greaterThan('bigTablet')} {
    width: calc(100% - ${(props) => (props.$compactSize ? drawerWidth * 2 : drawerWidth)}px);
  }

  ${media.lessThan('tablet')} {
    width: 100%;
  }
`;

const ContentWrapper = styled(Flex)`
  margin-left: ${drawerWidth}px;
  position: relative;
  height: calc(100vh - ${theme.headerSize});
  margin-right: 0;

  ${media.greaterThan('bigTablet')} {
    margin-right:${(props) => props.extraMargin ? drawerWidth : 0}px;
  }

  ${media.lessThan('tablet')} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const ViewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();
  const urlPageId = useNewViewPageId();
  const course = useSelector(selectCourse);
  const isNotesOpen = useSelector(selectNotesSliderToggle);

  useEffect(() => {
    dispatch(fetchCourseById({ courseId })).then((res) => {
      const courseData = res.payload;
      if (urlPageId) {
        navigate(`/view/course/${courseId}/${urlPageId}`, { replace: true });
      } else if (courseData?.toc?.[0]?.pages?.[0]?.id) {
        const initialPage = courseData.lastViewedPageId || courseData.toc[0].pages[0].id;
        navigate(`/view/course/${courseId}/${initialPage}`, { replace: true });
      }
    });
  }, [courseId, urlPageId, dispatch, navigate]);

  const { prev, next } = usePageNavigation(course?.toc || [], urlPageId);

  const goToPage = (pageId) => {
    navigate(`/view/course/${courseId}/${pageId}`, { replace: true });
  };

  const handleOpenNotes = () => {
    dispatch(setNotesSliderToggle(!isNotesOpen));
  };

  const themeColor = useMemo(()=> getUserThemeColor(course?.theme?.color), [course?.theme?.color]);
  const superLightColor = useMemo(()=> getLighterThemeColor(themeColor, 0.95), [themeColor])
  const lightColor = useMemo(() => getLighterThemeColor(themeColor), [themeColor]);
  const darkColor = useMemo(() => getDarkerThemeColor(themeColor), [themeColor]);

  const RenderNavButtons = React.memo(({ prev, next }) => {
    return (
      <NavButtonWrapper $compactSize={isNotesOpen} backgroundColor={lightColor}>
        <Buttons.NavButton
          disabled={!prev}
          onClick={() => goToPage(prev?.id)}
          $align='left'
          label={prev?.name || ''}
          $with='calc(50% - 22px)'
          $hoverColor={darkColor}
          $bgcolor={lightColor}
        />
        <IconAsButtonStyled
          backgroundColor={superLightColor}
          iconName='EditNote'
          tooltipLabel='Your notes'
          color={themeColor}
          clickHandle={handleOpenNotes}
        />
        <Buttons.NavButton
          disabled={!next}
          onClick={() => goToPage(next?.id)}
          $align='right'
          label={next?.name || ''}
          $with='calc(50% - 22px)'
          $hoverColor={darkColor}
          $bgcolor={lightColor}
        />
      </NavButtonWrapper>
    );
  });

  const isQuiz = useMemo(() => {
    return course?.settings?.quiz?.enabled && course?.settings?.quiz?.pages?.hasOwnProperty(urlPageId);
  }, [course, urlPageId]);

  return (
    <>
      <TocMenu />
      <ContentWrapper direction='column' extraMargin={isNotesOpen}>
        <CoursePage 
          pageId={urlPageId} 
          isQuiz={isQuiz} 
          quizData={isQuiz ? course.settings.quiz.pages[urlPageId] : null} 
        />
        <NotesMobile makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
        <RenderNavButtons prev={prev} next={next} />
      </ContentWrapper>
      <NotesDrawer makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
    </>
  );
};

export default ViewCourse;

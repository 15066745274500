import React from 'react';
import { truncate } from '../../../helpers/stringHelpers';
import { Flex, theme } from '../../../ui';
import DownloadableFile from '../../../ui/DownloadableFile';
import styled from 'styled-components';

const FlexStyled = styled(Flex)`
  margin-left: -5px;
`

const DownloadableAttach = ({ files, themeColor, asButton }) => {
  // Convert files to an array if it's a single object, ignore if it's empty
  const fileList = Array.isArray(files) ? files : files && Object.keys(files).length > 0 ? [files] : [];
  
  return (
    !!fileList.length && (
      <FlexStyled direction={'column'} align={'baseline'} gap='10'>
        {fileList.map((file, index) => (
          <Flex align={'center'} gap={10} key={`${file.id}-${index}`}>
            { asButton ? <DownloadableFile.AsButton $bgcolor={themeColor || theme.colors.PRIMARY} $variant='customized' label={truncate(file.name, 50)} file={file} /> 
              : <DownloadableFile.AsAttach label={truncate(file.name, 50)} file={file} />}
          </Flex>
        ))}
      </FlexStyled>
    )
  );
};

export default DownloadableAttach;

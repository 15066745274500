import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSelect, Flex, Typo, Modal } from '../../../../ui';

const BuildSpotlightCourses = ({
  allCourses,
  info,
  open,
  onClose,
  addToPreview
}) => {
  const { t } = useTranslation();

  const [highlightedCourses, setHighlightedCourses] = useState([]);

  useEffect(() => {
    if (info?.filter(el => el !== null).length > 0) {
      setHighlightedCourses(info)
    }
  }, [info])

  const onAccept = useCallback(() => {
    addToPreview(highlightedCourses)
  }, [highlightedCourses, addToPreview]);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel={t('webshop.addPreview')}
    >
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.spotlightedCoursesTitle')}</Typo.ContentSmallHeader>
      <Typo.Text>{t('webshop.build.spotlightedCoursesText')}</Typo.Text>
      <SearchSelect
        multiple
        id="checkboxes-highlighted-courses"
        options={allCourses}
        selectedOptions={highlightedCourses}
        label={t('globals.courses')}
        style={{ width: 500 }}
        setValue={setHighlightedCourses}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Flex>
    </Modal>
  );
};

export default BuildSpotlightCourses;

import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '..';
import Typo from '../Typo';
import { 
  getUserThemeContrastColor, 
  getDarkerThemeColor, 
  getLighterThemeColor } from '../theme/colors';

// import { useHistory } from 'react-router-dom';
// import Spinner from '../Spinner';
// import { track } from 'lib/tracker';

const ButtonComponent = ({ onClick, children, loading, $asLink, $asText, trackEvent, ...props }) => {
  // const history = useHistory();

  const { disabled } = props;

  const handleClick = useCallback((event) => {
    if (onClick) {
      onClick(event);
    }
    if (trackEvent) {
      //   track(trackEvent);
    }
  }, [onClick, trackEvent]);

  if ('href' in props && props.href && !disabled && !loading) {
    const { href, newTab, ...rest } = props;
    const handleLinkClick = (event) => {
      handleClick(event);
      if (href && !href.startsWith('http') && !event.isDefaultPrevented()) {
        event.preventDefault();
        // history.push(href);
      }
    };

    const target = newTab ? '_blank' : undefined;
    const rel = newTab ? 'noreferrer noopener' : undefined;

    return (
      <a onClick={handleLinkClick} href={href} target={target} rel={rel} {...rest}>
        <Typo.Info>{children}</Typo.Info>
      </a>
    );
  }

  const IconComponent = props.icon && Icon[props.icon]

  return (
    <button onClick={handleClick} {...props}>
      {props.icon && <IconComponent size='small' />}
      <Typo.MediumText fontWeight='500' $textAlign='center' >{children}</Typo.MediumText>
    </button>
  );
};

const Button = styled(ButtonComponent).attrs(({ loading, disabled, ...props }) => {
  const href = 'href' in props ? props.href : undefined;

  return {
    disabled: loading === true ? true : disabled,
    href: (disabled || loading) && href ? undefined : href,
    loading,
  };

})` 
  justify-content:center;
  background-color: ${(props) => props.theme.colors.PRIMARY}; 
  border: 1px solid transparent; 
  color: ${(props) => props.theme.colors.NEUTRAL_0}; 
  border-radius: 22px; 
  cursor: pointer; 
  padding: 10px 15px; 
  min-width: 2.75rem; 
  display: flex; 
  gap: 8px;
  min-width:120px;
  transition: all 0.15s ease; 
  p {
    color: ${(props) => props.theme.colors.NEUTRAL_0}; 
    text-align: left;
  }  
  &:hover { 
    background-color: ${(props) => props.theme.colors.PRIMARY_LIGHT}; 
    border-color: transparent; 
    text-decoration: none; 
    color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
    p {
      color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
    }
  }

  &:focus {
    z-index: 1; 
    text-decoration: none; 
    outline: none; 
    border-color: transparent; 
    box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW};
  }

  &:disabled, 
  &[aria-disabled='true'] { 
    background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
    border-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
    cursor: not-allowed; 
    color: ${(props) => props.theme.colors.NEUTRAL_20}; 
    p {
      color: ${(props) => props.theme.colors.NEUTRAL_20}; 
    }
  }

  ${({ $variant }) =>
    $variant === 'action' &&
    css`
      background-color: ${(props) => props.theme.colors.ACTION};
      color: ${(props) => props.theme.colors.NEUTRAL_0};
      border-color: ${(props) => props.theme.colors.ACTION};
      p {
        color: ${(props) => props.theme.colors.NEUTRAL_0}; 
      }

      &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.colors.ACTION};
        p {
          color: ${(props) => props.theme.colors.ACTION}; 
        }
        border-color: ${(props) => props.theme.colors.ACTION};
      }
  `}

  ${({ $variant }) =>
    $variant === 'square' &&
    css`
      border-radius: 0;
      align-items:center;
      background-color: ${(props) => props.$bgcolor || props.theme.colors.NEUTRAL_PRIMARY};
      border-color: ${(props) => props.$bgcolor || props.theme.colors.NEUTRAL_PRIMARY}; 
      text-decoration: none; 
      color: ${(props) => props.$bgcolor ? getUserThemeContrastColor(props.$bgcolor) : props.theme.colors.NEUTRAL_0};
      p {
        color: ${(props) => props.$bgcolor ? getUserThemeContrastColor(props.$bgcolor) : props.theme.colors.NEUTRAL_0};
      } 
      &:hover {
        background-color: ${(props) => props.$hoverColor || 'transparent'};
        color: ${(props) => props.$hoverColor ? getUserThemeContrastColor(props.$hoverColor) : props.theme.colors.NEUTRAL_PRIMARY};
        p {
          color: ${(props) => props.$hoverColor ? getUserThemeContrastColor(props.$hoverColor) : props.theme.colors.NEUTRAL_PRIMARY}; 
        }
        border-color: ${(props) => props.$hoverColor ? props.$hoverColor : props.theme.colors.NEUTRAL_PRIMARY};
      }

      &:disabled, &[aria-disabled='true'] {
        background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        border-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        }
        
      }
    `}

  ${({ $variant }) =>
    $variant === 'customized' &&
    css`
      background-color: ${(props) => props.$bgcolor || props.theme.colors.NEUTRAL_PRIMARY}; 
      border-color: ${(props) => props.$bgcolor || props.theme.colors.NEUTRAL_PRIMARY}; 
      text-decoration: none; 
      color: ${(props) => props.$bgcolor ? getUserThemeContrastColor(props.$bgcolor) : props.theme.colors.NEUTRAL_0};
      p {
        color: ${(props) => props.$bgcolor ? getUserThemeContrastColor(props.$bgcolor) : props.theme.colors.NEUTRAL_0};
      } 

      &:hover {
        background-color: ${(props) => props.$hoverColor || 'transparent'};
        color: ${(props) => props.$hoverColor ? getUserThemeContrastColor(props.$hoverColor) : props.theme.colors.NEUTRAL_PRIMARY};
        p {
          color: ${(props) => props.$hoverColor ? getUserThemeContrastColor(props.$hoverColor) : props.theme.colors.NEUTRAL_PRIMARY}; 
        }
        border-color: ${(props) => props.$hoverColor ? props.$hoverColor : props.theme.colors.NEUTRAL_PRIMARY};
      }

      &:disabled, &[aria-disabled='true'] {
        background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        border-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        }
        
      }
  `}
  ${({ $variant }) =>
    $variant === 'primary' &&
    css`
      background-color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      border-color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      text-decoration: none; 
      color: ${(props) => props.theme.colors.NEUTRAL_0};
      p {
        color: ${(props) => props.theme.colors.NEUTRAL_0}; 
      } 

      &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        }
        border-color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
      }

      &:disabled, &[aria-disabled='true'] {
        background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        border-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        }
        
      }
  `}
  ${({ $variant }) =>
    $variant === 'themed' &&
    css`

      background-color: ${(props) => props.$themeColor ? getLighterThemeColor(props.$themeColor) : props.theme.colors.PRIMARY_LIGHT}; 
      border-color: ${(props) => props.$themeColor ? getLighterThemeColor(props.$themeColor) : props.theme.colors.PRIMARY_LIGHT}; 
      text-decoration: none; 
      color: ${(props) => props.$themeColor ? getDarkerThemeColor(props.$themeColor) : props.theme.colors.PRIMARY};
      p { color: ${(props) => props.$themeColor ? getDarkerThemeColor(props.$themeColor) : props.theme.colors.PRIMARY};} 

      &:hover {
        background-color: ${(props) => props.$themeColor ? getDarkerThemeColor(props.$themeColor) : props.theme.colors.PRIMARY};
        border-color: ${(props) => props.$themeColor ? props.theme.colors.NEUTRAL_0 : props.theme.colors.PRIMARY};
        color: ${(props) => props.theme.colors.NEUTRAL_0};
        p {color: ${(props) => props.theme.colors.NEUTRAL_0}; }
      }

      &:disabled, &[aria-disabled='true'] {
        background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        border-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT}; 
        color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        }
        
      }
  `}
  ${({ $variant }) =>
    $variant === 'basic' &&
    css`
      background-color: ${(props) => props.theme.colors.NEUTRAL_0};
      color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
      p {
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
        color: ${(props) => props.theme.colors.NEUTRAL_0};
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_0}; 
        }
      }
  `}
  ${({ $asText }) =>
    $asText &&
    css` 
      background: none; 
      border: none; 
      box-shadow: none; 
      color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      padding: 0;
      text-decoration: underline;
      min-width:0;
      
      p{
        font-weight: 500;
        font-size: ${(props) => props.theme.fontSizes.small};
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      }

      &:hover { 
        background: none; 
        border: none; 
        color: ${(props) => props.theme.colors.PRIMARY}; 
        p {
          color: ${(props) => props.theme.colors.PRIMARY}; 
        }
      }

      &:focus, 
      &:active { 
        box-shadow: none; 
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        }
      }
  `} 
      
  ${({ $asLink }) =>
    $asLink &&
    css` 
      background: none; 
      border: none; 
      box-shadow: none; 
      color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      font-weight: 300;
      p {
        font-weight: 300;
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
      }
      padding: 0;
      text-decoration: none;

      &:hover { 
        background: none; 
        border: none; 
        text-decoration: underline;
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        }
      }

      &:focus, 
      &:active { 
        box-shadow: none; 
        color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY};
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_PRIMARY}; 
        } 
      }

      &:disabled, 
      &[aria-disabled='true'] { 
        background: none; 
        cursor: not-allowed; 
        color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        p {
          color: ${(props) => props.theme.colors.NEUTRAL_20}; 
        }
        &:hover { 
          text-decoration: none;
        }
      }
  `} 
`;
export default Button;

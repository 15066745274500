import React from 'react'
import theme from '../theme';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import styled from 'styled-components';
import { media } from '../../utils/media';

const StyledButton = styled(Button)`
  height:${(props) => props.$height};
  justify-content: ${(props) => props.$align};
  margin-left:${(props) => props.$marginLeft};
  display:${(props) => props.$onlyMobile && 'none'};
  width:${(props) => props.$with};
  ${media.lessThan('tablet')} {
    display:${(props) => props.$onlyMobile && 'inherit'};
  }
`

const ButtonsComponent = ({ label, bgColor, variant, otherProps }) => {
  const { t } = useTranslation();
  
  return (
    <StyledButton $bgcolor={bgColor} $variant={variant} {...otherProps} >{t(label)}</StyledButton>
  )
};


const Buttons = {
  Cancel: (props) =>  <ButtonsComponent label='globals.cancel' bgColor={theme.colors.NEUTRAL_20} variant='customized' otherProps={props} />,
  NavButton: (props) => <ButtonsComponent label={props.label} variant='square' otherProps={props} />
}

export default Buttons;

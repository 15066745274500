import React, { useEffect, useState } from 'react';
import {  Button, Flex, Loading, Typo, theme } from '../../../../ui';
import Results from '../TaskModule/Results';

const ResultsScreen = ({ canShowResults, pageInfo, quizSettings, lastQuiz, handleStartQuiz, themeColor }) => {

  const [results, setResults] = useState(null)

  useEffect(()=>{
    const data = pageInfo.pageRows
      .flatMap(row => row.pageModules)
      .map(pageModule => {
      const {
        id,
        data: {
          params: { task, taskResults }
        }
      } = pageModule;
      return { ...task, pageModuleId: id, taskResults };
    });

    setResults(data)

  }, [pageInfo])

  return( !lastQuiz ? <Loading themeColor={themeColor}/> :
    <>
      <Typo.BigText>{lastQuiz.status === 'QUIZ_SUCCESS' ? quizSettings?.successText : quizSettings?.failText}</Typo.BigText>
      <Typo.TextTitle>Your score: {lastQuiz.score} / {lastQuiz.total}</Typo.TextTitle>
      {canShowResults && !!results && <Flex direction='column' width='100%' align='strech'>
        {results?.map(({ taskResults, pageModuleId, ...task }) => 
          <div key={`${task.id}-${pageModuleId}`}>
            <Typo.MediumText>{task.caption.main}</Typo.MediumText>
            <Results taskOptions={task.caption.items} result={taskResults[taskResults.length - 1]} taskType={task.type} key={task.id}/>
          </div>
        )}
      </Flex>}
      {!!handleStartQuiz && <Button $variant='customized' $bgcolor={themeColor} onClick={handleStartQuiz}>Start Quiz</Button>}
    </>
  )
};

export default ResultsScreen;

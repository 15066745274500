import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components'

import { Flex, Typo, Modal, Textinput, theme } from '../../../ui';
import FileUploader from '../../media/uploaders/FileUploader';
import DownloadableAttach from './DownloadableAttach';
import EditableAttach from './EditableAttach';
import { useTranslation } from 'react-i18next';
import SplitButton from '../../../ui/SplitButton';

const Divider = styled.hr`
  border-top: 1px dashed ${theme.colors.NEUTRAL_40};
  width:100%;
`

const EditFeedback = ({ onClose, content, onAccept }) => {
  const { t } = useTranslation();
  // const [gradeIndex, setGradeIndex] = useState();
  const [feedbackFiles, setFeedbackFiles] = useState([]);
  const feedbackMessageRef = useRef('');

  const actions = [
    { label: `${t('tasks.saveFeedback')} - ${t('tasks.approved')}`, key: 1, color: theme.colors.SUCCESS_GREEN },
    { label: `${t('tasks.saveFeedback')} - ${t('tasks.notApproved')}`, key: 0, color: theme.colors.ERROR_RED }
  ];


  useEffect(() => {
    // setGradeIndex(feedbackGradeIndex)
    setFeedbackFiles(content.answer?.feedback?.files);
  }, [content])

  const deleteFeedbackFile = useCallback((file) => {
    const result = feedbackFiles.filter(el => el.id !== file.id);
    setFeedbackFiles(result);
  }, [feedbackFiles])

  const addFeedbackFile = file => {
    feedbackFiles && setFeedbackFiles([...feedbackFiles, file]);
  }

  const saveFeedback = (gradeSelected) => {
    let text = `${feedbackMessageRef.current.value}`;
    feedbackFiles.forEach(file => text = `${text}[file:${file.id}]`);
    const feedbackResult = {
      id: content.answer.id,
      grade: gradeSelected.key,
      text,
    }
    onAccept(feedbackResult);
  }

  const feedbackGradeIndex = actions.findIndex((item) => +item.key === +content.answer?.feedback?.grade)

  return (
    <Modal
      open={!!content.answer}
      onClose={onClose}
      title={t('tasks.submissionFeedback')}
      width='900px'
      dialogActionsChildren={
        <SplitButton options={actions} onSubmit={saveFeedback} preselectedIndex={feedbackGradeIndex} />
      }
    >
      <Flex direction='column' gap='30' align='flex-start'>
        <Flex direction='column' gap='10' align='flex-start'>
          <Typo.TextTitle>{content.userName}</Typo.TextTitle>
          {content.answer?.value && <Typo.Text>{content.answer?.value}</Typo.Text>}
          <Typo.Info>{content.answer?.created}</Typo.Info>
          <DownloadableAttach files={content.answer?.files} />
        </Flex>
        <Divider />
        <Typo.TextTitle>{t('tasks.teacherFeedback')}</Typo.TextTitle>
        <Flex direction='column' width='100%' align='flex-start' gap='10'>
          <Textinput
            isMultiline
            rows='8'
            label={t('tasks.feedbackMsg')}
            defaultValue={content.answer?.feedback?.text}
            inputRef={feedbackMessageRef} />
          <Flex width='100%' justify='space-between'>
            <EditableAttach files={feedbackFiles} handleDelete={deleteFeedbackFile} />
            <FileUploader id={'filePicker'} saveAction={file => addFeedbackFile(file)} noMediaLibrary/>
          </Flex>
        </Flex>
      </Flex>
    </Modal>

  );
};

export default EditFeedback;

import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { inviteApi, invitationApi, guestApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";


// ----------------- Thunks -----------------------------

export const generateGiftCodes = createAsyncThunk('invites/generateGiftCodes', async ({ amount, courseId, objectType }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { objectId: courseId, objectType: objectType || 'course', n: +amount },
    method: 'generateGiftCodes'
  };

  const res = await httpClient.post(invitationApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
})

export const checkInvitation = createAsyncThunk('invites/checkInvitation', async ({ hash, giftCode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { hash, giftCode },
    method: 'checkInvitation'
  };

  const res = await httpClient.post(guestApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const acceptInvitation = createAsyncThunk('invites/acceptInvitation', async ({ hash, giftCode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { hash, giftCode },
    method: 'acceptInvitation'
  };

  const res = await httpClient.post(guestApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const fetchInvitations = createAsyncThunk('invites/fetchInvitations', async ({ searchText }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: searchText ? { searchText: searchText } : {},//search by email
    method: 'prepareForUserList',
    pageLength: 1000,
    pageStart: 0,
  };

  const res = await httpClient.post(invitationApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})
export const fetchInviteById = createAsyncThunk('invites/fetchInviteById', async ({ id }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id },
    method: 'showInfo'
  };

  const res = await httpClient.post(invitationApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})


export const deleteInvite = createAsyncThunk('invites/deleteInvite', async (id, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(invitationApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})
export const resendInvite = createAsyncThunk('invites/resendInvite', async (id, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id },
    method: 'resend'
  };

  const res = await httpClient.post(invitationApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const sendInvitations = createAsyncThunk('invites/sendInvitations', async ({ byWhat, expires, courseIds, withWhat, circleId }, { getState, dispatch, rejectWithValue }) => {
  let method, data;

  if (byWhat === 'groups') {
    method = 'byCircleIds'
    data = { circleIds: withWhat, courseIds, expires }
  } else if (byWhat === 'users') {
    method = 'byUserIds'
    data = { userIds: withWhat, courseIds, expires }
  } else {
    method = 'byEmails'
    data = { emails: withWhat, courseIds, expires, circleId }// we can now send role: reader or builder
  }

  const body = {
    data,
    method
  };

  const res = await httpClient.post(inviteApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

// ----------------- Reducers ---------------------------
const invitationsAdapter = createEntityAdapter()

const initialState = {
  isInviteModalOpen: false,
  modalProps: {},
  status: 'idle',
  sendStatus: 'idle',
  checkStatus: 'idle',
  ids: [],
  entities: {},
  redeemInvitation: {},
}

const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    setInviteModalIsOpen(state, action) {
      state.isInviteModalOpen = action.payload === Object(action.payload) ? true : action.payload
      state.modalProps = action.payload
    },

  },
  extraReducers: builder => {
    builder
      .addCase(fetchInvitations.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(sendInvitations.pending, (state, action) => {
        state.sendStatus = STATUS_LOADING;
      })
      .addCase(sendInvitations.rejected, (state, action) => {
        state.sendStatus = STATUS_FAILED;
        enqueueSnackbar(action.payload?.response?.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(fetchInvitations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchInvitations.fulfilled, (state, action) => {

        state.status = STATUS_SUCCEEDED;
        invitationsAdapter.setAll(state, action.payload)
      })
      .addCase(sendInvitations.fulfilled, (state, action) => {
        state.sendStatus = STATUS_SUCCEEDED;
        enqueueSnackbar('Invitation(s) sent with success', { variant: TOAST_SUCCESS });
      })
      .addCase(fetchInviteById.fulfilled, (state, action) => {

        state.status = STATUS_SUCCEEDED;
        invitationsAdapter.updateOne(state, { id: action.payload.id, changes: { ...action.payload } })
      })
      .addCase(resendInvite.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar('Invitation resent with success', { variant: TOAST_SUCCESS });
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar('Invitation accepted with success', { variant: TOAST_SUCCESS });
      })
      .addCase(resendInvite.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(deleteInvite.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });

      })
      .addCase(acceptInvitation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });

      })
      .addCase(deleteInvite.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        invitationsAdapter.removeOne(state, action.meta.arg)
        enqueueSnackbar('Successfully deleted invitation.', { variant: TOAST_SUCCESS });
      })
      .addCase(checkInvitation.fulfilled, (state, action) => {
        state.checkStatus = STATUS_SUCCEEDED;
        const data = action.payload[0];
        state.redeemInvitation = {
          ...data,
          email: data.email ? data.email.toLowerCase() : 'Not provided'
        }
      })
      .addCase(checkInvitation.rejected, (state, action) => {
        state.checkStatus = STATUS_FAILED;
        state.redeemInvitation = { errMsg: action.payload.response.errMsg }
      })
      .addCase(checkInvitation.pending, (state, action) => {
        state.checkStatus = STATUS_LOADING;
      })
  }
})

export const { setInviteModalIsOpen } = invitesSlice.actions

export default invitesSlice.reducer

// ----------------- Selectors --------------------------

export const {
  selectAll: selectInvitations,
  selectById: selectInvitationById,
} = invitationsAdapter.getSelectors((state) => state.invites)

export const selectIsInvitesModalOpen = state => state.invites.isInviteModalOpen;
export const selectModalProps = state => state.invites.modalProps;
export const selectStatus = state => state.invites.status;
export const selectSendStatus = state => state.invites.sendStatus;
export const selectCheckStatus = state => state.invites.checkStatus;
export const selectRedeemInvitation = state => state.invites.redeemInvitation;

import React, { useEffect, useState } from 'react';
import { Loading, Page } from '../../../../ui';
import { coursePageNavigation } from '../../../../utils/constants';
import StartScreen from './StartScreen';
import QuizQuestions from './QuizQuestions';
import ResultsScreen from './ResultsScreen';

const Quiz = ({ quizData, pageInfo, themeColor }) => {

  const [startTime, setStartTime] = useState(null);
  const [isQuizStarted, setQuizStarted] = useState(false);
  const [isQuizEnded, setQuizEnded] = useState(false);
  const [lastQuizData, setLastQuizData] = useState(false);
  const [hasPrevAnswers, setHasPrevAnswers] = useState(false)
  const [canShowResults, setCanShowResults] = useState(false)

  useEffect(() => {
    if(pageInfo.userdata){
      const lastQuizzes = pageInfo?.userdata?.pages[pageInfo.id];
      const lastQuiz = lastQuizzes[lastQuizzes?.length - 1];
      setLastQuizData(lastQuiz)
      setHasPrevAnswers(pageInfo.userdata.locked)
      setCanShowResults(pageInfo.userdata.locked && !quizData.hideAnswers)
    }

  }, [pageInfo.id, pageInfo.userdata, quizData.hideAnswers])

  const handleStart = () => {
    setQuizStarted(true);
    setQuizEnded(false);
    setStartTime(new Date());
  }

  const handleEndQuiz = (res)=>{
    setLastQuizData(res)
    setQuizEnded(true);
  }

  return (!pageInfo ? <Loading themeColor={themeColor} /> :
    <Page substractBottom={coursePageNavigation} gap="20">
      {!isQuizStarted ?
        <StartScreen
          quizSettings={quizData}
          pageInfo={pageInfo}
          hasPrevAnswers={hasPrevAnswers}
          lastQuizData={lastQuizData}
          canShowResults={canShowResults}
          themeColor={themeColor}
          showResults={!quizData?.repeatable && !!lastQuizData?.length}
          handleStartQuiz={handleStart} />
        : isQuizEnded ?
            <ResultsScreen
              canShowResults={canShowResults}
              quizSettings={quizData}
              lastQuiz={lastQuizData}
              pageInfo={pageInfo}
              themeColor={themeColor}
              handleStartQuiz={handleStart} />
            : <QuizQuestions
                setQuizEnded={handleEndQuiz}
                quizData={quizData}
                pageInfo={pageInfo}
                themeColor={themeColor}
                startTime={startTime} />
      }
    </Page>
  )
};

export default Quiz;

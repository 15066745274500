import React, {useEffect, useState} from 'react';
import { theme } from '../../../../ui';
import styled from 'styled-components';
import { drawerWidth } from '../../../../utils/constants';
import { media } from '../../../../utils/media';
import NotesContent from './NotesContent';

const Wrapper = styled.div`
  width: ${drawerWidth}px;
  background-color: white;
  position: fixed;
  top: ${theme.headerSize};
  right: 0;
  height: calc(100vh - ${theme.headerSize});
  overflow: scroll;
  border-left: 1px solid ${ theme.colors.NEUTRAL_20};

  display: ${(props)=>props.makeItOpen ? 'inherit' : 'none'};

  ${media.lessThan('bigTablet')} {
    display:none;
  }
`


const NotesDrawer = ({makeItOpen, setNotesDrawerOpen}) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=>{
    setIsOpen(makeItOpen)
  },[makeItOpen])

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
    setNotesDrawerOpen(true)
  };

  const setNotesDrawerClosed = ()=>{
    setNotesDrawerOpen(false)
    toggleDrawer(false)
  }

  return (
    <Wrapper makeItOpen={makeItOpen}>
      <NotesContent />
    </Wrapper>

  );
}

export default NotesDrawer;

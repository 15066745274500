import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../utils/media';

const FlexContainer = styled.div`
  display: flex;
  width:${(props) => props.$size && props.$size};
  justify-content: ${(props) => props.$justifyContent || 'flex-start'};
  align-items: ${(props) => props.$alignItems || 'center'};
  align-self:${({$alignSelf}) => $alignSelf};
  flex-direction: ${(props) => props.$flexDirection || 'row'}${(props) => props.$isReverse ? '-reverse' : ''};
  max-width: ${(props) => props.$maxWidth || '100%'};
  min-width: ${(props) => props.$minWidth};
  gap: ${(props) => props.$gap || 0}px;
  flex-wrap: ${(props) => props.$wrap || 'nowrap'};
  flex: ${(props) => props.$auto && '1'};
  
  ${({ $breakPoint }) => css`
    ${media.lessThan(`${$breakPoint?.size}`)} {
      ${$breakPoint?.value};
    }
  `}}
`;


const Flex = ({ justify, align, direction, reverse, children, breakPoint, gap, width, maxWidth, minWidth, wrap, auto, alignSelf, ...props }) => {

  return (
    <FlexContainer
      $justifyContent={justify}
      $alignItems={align}
      $flexDirection={direction}
      $isReverse={reverse}
      $breakPoint={breakPoint}
      $gap={gap}
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $size={width}
      $wrap={wrap}
      $auto={auto}
      $alignSelf={alignSelf}
      {...props}

    >
      {children}
    </FlexContainer>
  )
};

export default Flex;

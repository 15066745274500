import { apiPath } from '../utils/urls';

export const imageChecker = (imageObject, passedId = null) => {
  const constructImageUrl = (id) => `${apiPath}image/${id}`;

  if (!imageObject) return null;

  const { avatarUrl, avatarId, image, imageId, url, id } = imageObject;

  if (avatarUrl) {
    return avatarUrl.includes('ig-0') && avatarId ? constructImageUrl(avatarId) : avatarUrl;
  }

  if (image) {
    return image.includes('ig-0') && imageId ? constructImageUrl(imageId) : image;
  }

  if (url) {
    return url.includes('ig-0') && id ? constructImageUrl(id) : url;
  }

  if (typeof imageObject === 'string' && imageObject.includes('amazonaws')) {
    return imageObject;
  }

  if (typeof imageObject === 'string' && !!passedId) {
    return constructImageUrl(passedId);
  }

  return null;
};

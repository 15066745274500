import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {Button, Flex, IconAsButton, Textinput, Typo, Avatar, theme} from '../index';
import { formatDistanceToNowStrict } from 'date-fns';
import styled from 'styled-components';
import { getLighterThemeColor } from '../theme/colors';
import DownloadableFile from '../DownloadableFile';

const ButtonsWrapper = styled(Flex)`
  // border-top: 1px solid ${theme.colors.NEUTRAL_40};
`

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  gap:10px;
  // background-color:pink;
  padding-left:${({$level}) => $level > 0 && `calc(${$level}*40px)`};
`
 
const MessageWrapper = styled(Flex)`
  border-radius: 10px;
  background-color:${({$themeColor}) => $themeColor || theme.colors.NEUTRAL_LIGHT};
  padding:10px;
`

const Message = ({ message, currentUserId, onDelete, onEdit, onReply, level, themeColor }) => {
  const [editContent, setEditContent] = useState(message.body);
  const [isEditing, setIsEditing] = useState(false);

  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [nextLevel, setNextLevel] = useState(level);

  // const isEditor = 

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const cancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleSave = useCallback(() => {
    onEdit(message.id, editContent)
    setIsEditing(false);
  }, [onEdit, message.id, editContent]);

  const handleReply = () => {
    setIsReplying(true);
  };

  const handleSendReply = () => {
    onReply(message.id, replyContent);
    setReplyContent('');
    setIsReplying(false);
  };

  const renderMessageFiles = useMemo(() => {
    if (message?.objects?.hasOwnProperty('files')) {
      return message.objects.files.map(file => 
        <DownloadableFile.AsText label={file.title} file={file.url}/>
      )
    }
  }, [message])

  //TO DO:
  // add confirmation modal to delete
  // after sendidng the message and the board gets updated attach files dont show up. only after refresh
  // make a diference from what is my own comments from others? should we have it or not
  // show comments ordered by new first
  // follow action api/post {"method":"follow","data":{"postId":180391}}
  // delete message should be available for editors and not just for authors

  
  const imAuthor = currentUserId === message.author.id

  const superLightColor = getLighterThemeColor(themeColor, 0.95)
  const lightColor = getLighterThemeColor(themeColor)
  

  useEffect(() => {
    if(message.children?.length > 0) setNextLevel(++level)
  }, [level])
  

  return (
    <Wrapper $level={level}>
      <Flex gap='10' width='100%' align='flex-start' $themeColor={imAuthor ? lightColor : superLightColor } >
        <Avatar size='xSmall' name={message.author.name} url={message.author.avatar} />
        <Flex width='100%'>
          <Flex direction='column' align='flex-start'>
            <MessageWrapper direction='column' align='flex-start'>
              <Flex gap='10'>
                <Typo.TextTitle>{message.author.name}</Typo.TextTitle>
              </Flex>
              {message.body && <Typo.Text>{message.body}</Typo.Text>}
              {!!renderMessageFiles && <div>{renderMessageFiles}</div>}
            </MessageWrapper>

            <ButtonsWrapper gap='5' width='100%' justify={'space-between'}>
              <Typo.Info>{formatDistanceToNowStrict(new Date(message.created.split(' ').join('T') + 'Z'), { addSuffix: true })}</Typo.Info>
                <Flex> {imAuthor ? <>
                    <IconAsButton size='small' iconName='Edit' clickHandle={handleEdit} />
                    <IconAsButton size='small' iconName='Delete' clickHandle={() => onDelete(message.id)} />
                  </>
                  : <>
                    <IconAsButton size='small' iconName='Reply' clickHandle={handleReply} />
                    <IconAsButton size='small' iconName='Follow' clickHandle={()=> console.log('Follow')} />
                  </>
                }</Flex>
              
            </ButtonsWrapper>
           
           
            {/* {!isEditing ? <> 
              {message.body && <Typo.Text>{message.body}</Typo.Text>}
              {!!renderMessageFiles && <div>{renderMessageFiles}</div>}
              <ButtonsWrapper gap='5' width='100%' justify={'flex-end'}>
                {imAuthor ? <>
                    <IconAsButton size='small' iconName='Edit' clickHandle={handleEdit} />
                    <IconAsButton size='small' iconName='Delete' clickHandle={() => onDelete(message.id)} />
                  </>
                  : <IconAsButton size='small' iconName='Reply' clickHandle={handleReply} />
                }
              </ButtonsWrapper>
            </> 
            : 
              <>
                <Textinput
                  type="text"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                />
                { !!renderMessageFiles && <div>{renderMessageFiles}</div>}
                <ButtonsWrapper gap='5' width='100%' justify={'flex-end'}>
                  <Button $asText onClick={handleSave}>Save</Button>
                  <Button $asText onClick={cancelEdit}>Cancel</Button>
                </ButtonsWrapper>
              </> 
            } */}


          </Flex>
        </Flex>
      </Flex>
      {isReplying && (
        <Flex>
          <Textinput
            type="text"
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder="Type your reply here..."
          />
          <IconAsButton size='small' iconName='Send' clickHandle={handleSendReply} />
        </Flex>
      )}

      {message.children?.length > 0 &&
        message.children.map(childMessage => {
          console.log('more children', level, nextLevel)
          return <Message
            key={childMessage.id}
            message={childMessage}
            currentUserId={currentUserId}
            onDelete={onDelete}
            onEdit={onEdit}
            level={nextLevel}
            onReply={onReply}
            themeColor={themeColor}
          />
        }
          
      )}
    </Wrapper>
  );
};

export default Message;

import React, { useCallback, useState } from 'react';
import { Button, Loading } from '../../ui';

const Downloadable = ({file, ...props}) => {

  const [loadedValue, setLoadedValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const countLoadedValue = useCallback((loaded, total) => {
    setLoadedValue(((loaded / total) * 100).toFixed());
  }, [])


  const onFileClick = useCallback(() => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', `${file.url}?${(new Date()).getTime()}`, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader("Origin", window.location.origin);
    xhr.send();

    xhr.onprogress = (event) => {
      if (!isLoading) setIsLoading(true);
      countLoadedValue(event.loaded, event.total)
    };
    xhr.onload = () => {
      setIsLoading(false);
      const blob = new Blob([xhr.response], { type: xhr.type || xhr.response.type });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';
      a.rel = 'noreferrer';
      a.download = file.name;
      a.click()
    };
  }, [file, isLoading, countLoadedValue]);

  return isLoading ? <Loading type={'page'} value={loadedValue} loadingType={'withProgress'} fullscreen={true} /> : <Button {...props} onClick={onFileClick}>{props.label}</Button>
}

const DownloadableFile = {
  AsAttach: props => <Downloadable icon='AttachFile' file={props.file} $asText {...props}/>,
  AsButton: props => <Downloadable icon='Download' file={props.file} {...props}/>,
  AsText: props => <Downloadable $asText file={props.file} label={props.label} {...props}/>,
}

export default DownloadableFile;

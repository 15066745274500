import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Loading, Modal, Textinput, Typo, Button, Flex } from '../../../ui';
import { generateGiftCodes } from '../../invites/invitesSlice';
import { CSVLink } from '../../createCsv';
import dayjs from 'dayjs';
// import { useTranslation } from 'react-i18next';
import CheckboxButton from '../../../ui/CheckboxButton';


const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const GenerateGiftCodesModal = ({ course, onClose, isAdmin }) => {
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  const amountRef = useRef(null);
  const idRef = useRef(null);
  const [exportData, setExportData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isCoursePackage, setIsCoursePackage] = useState(false);

  const handleCreateGiftCodes = useCallback(() => {
    setLoading(true)
    dispatch(generateGiftCodes({
      amount: amountRef.current.value,
      objectType: isCoursePackage ? 'package' : 'course',
      courseId: idRef?.current?.value || course?.id
    }))
      .then((res) => {
        const codes = res.payload.codes || res.payload ;
        const data = codes.map(item => ({
          codes: item,
          expire: res.payload.expiry,
        }))
        setLoading(false)
        setExportData(data)
      })
  }, [dispatch, course, setLoading, setExportData, isCoursePackage]);

  const toggleCoursePackage =(e)=>{
    setIsCoursePackage(e.target.checked)
  }

  return (
    <Modal
      open={!!course}
      onClose={onClose}
      title={course?.name || 'Type your course'}
      width='650px'
    >
      <Flex direction='column' gap='10' width='100%'>
        {isAdmin && !course?.id && <Flex direction='column'width='100%' align='flex-start'>
          <Typo.Text>course id</Typo.Text>
          <Textinput
            type='number'
            inputRef={idRef}
            margin='dense'
            id='courseId'
            fullWidth
          />
          <CheckboxButton
            labelText="the course is a package"
            checked={isCoursePackage}
            handleChange={toggleCoursePackage}
          />
        </Flex>}
        <Typo.Text>Specify how many codes to create</Typo.Text>
        <Textinput
          autoFocus
          type='number'
          inputRef={amountRef}
          margin='dense'
          id='giftCodes'
          fullWidth
        />
      </Flex>

      <Button icon='Sync' disabled={amountRef?.current?.value} onClick={handleCreateGiftCodes}>{'Create gift codes'}</Button>
      <Controls>
        {loading ? <Loading /> :
          exportData?.length && <CSVLink
            data={exportData}
            filename={`gift codes ${course?.name || idRef?.current?.value} ${dayjs().format('YYYY-MM-DD')}.csv`}
          >
            Download file
          </CSVLink>}
      </Controls>

    </Modal>
  );
};

export default GenerateGiftCodesModal;

import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components'
import { IconAsButton, theme } from '../../../ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectImage } from '../../fileUploader/fileSlice';
import { useTranslation } from 'react-i18next';
import FileUploader, { UPLOADER_TYPE } from './FileUploader';

const ImageSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  position: relative;
  flex: 1;

  button {
    position: absolute;
    z-index: 3;
    background: ${(props) => props.theme.colors.NEUTRAL_0};
    bottom: 10px;
    right: 10px;
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: 200px;
  align-items: center;
  min-width:30%;
`

const ImageUploader = ({
  saveAction,
  imageToPreview,
  id,
  type,
  label,
  iconName='Upload',
  children,
  saveByDelete,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const [error, setError] = useState(0); // eslint-disable-line
  const [hiddenImage, setHiddenImage] = useState(true);

  const file = useSelector(selectImage);

  useEffect(() => {
    if (imageToPreview) {
      setHiddenImage(false);
    }
  }, [imageToPreview]);

  useEffect(() => {
    if (file && file?.mockedType === type) {
      saveAction({ id: file.id, url: file.url, mockedType: file.mockedType });
      dispatch({ type: 'clearImage' })
      setHiddenImage(false);
    }
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  //TODO: figure out why we need this stuff
  // useEffect(() => {
  //   function onCompDestroy() {
  //     saveAction(null);
  //     dispatch({ type: 'clearImage' });
  //   }
  //   onCompDestroy();
  // }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteImage = useCallback(() => {
    setHiddenImage(true);
    if (saveByDelete) {
      saveAction({ id: -1, url: null, mockedType: null });
    }
    dispatch({ type: 'clearImage' })
  }, [dispatch, saveAction, saveByDelete]);

  return (
    children && !hiddenImage /* && imageToPreview.mockedType === type */ ?
      <ImageSectionWrapper>
        <IconAsButton
          iconName='Delete'
          variant='action'
          tooltipLabel={t('globals.delete')}
          color={theme.colors.ACTION}
          clickHandle={deleteImage}
        />
        {children}
      </ImageSectionWrapper>
      : <Wrapper>
        
        <FileUploader
          id={id || 'imageuploader'}
          saveAction={saveAction}
          uploaderType={UPLOADER_TYPE.IMAGE}
          fileType='image'
          label={label || t('mediaLibrary.uploadImage')}
          iconName={iconName}
          accept='image/*'
          type={type}
        /></Wrapper>
  )
};

export default ImageUploader;

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Figure, Flex, Modal, Textinput, Typo } from '../../../../ui';
import ImageUploader from '../../../media/uploaders/ImageUploader';

const EditTeacher = ({ open, info, onClose, addToPreview }) => {

  const { t } = useTranslation();

  const [teacherInfo, setTeacherInfo] = useState();

  useEffect(() => {
    setTeacherInfo(info)
  }, [info])

  const editTeacherInfo = useCallback(e => {
    setTeacherInfo({ ...teacherInfo, [e.target.dataset.propname]: e.target.value });
  }, [teacherInfo]);

  const addImageToTeacher = useCallback(image => {
    setTeacherInfo({ ...teacherInfo, avatarUrl: image?.url, avatarId: image?.id })
  }, [teacherInfo])

  const onAccept = useCallback(() => {
    addToPreview(teacherInfo)
  }, [teacherInfo, addToPreview]);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel={t('webshop.addPreview')}
    >
      <Flex direction='column' width='100%' align='flex-start'>
        <Typo.ContentSmallHeader>{t('webshop.build.teacherTitle')}</Typo.ContentSmallHeader>
        <Flex width='100%' gap='20' breakPoint={{ size: 'phablet', value: 'flex-direction:column;' }}>
          <ImageUploader
            saveByDelete={true}
            type={'teacher'}
            id={'teacher'}
            saveAction={addImageToTeacher}
            imageToPreview={teacherInfo?.avatarUrl}
          >
            {teacherInfo?.avatarUrl && <Figure.Rectangle ><img alt={'imageToPreview'} src={teacherInfo?.avatarUrl} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
          <Flex auto direction='column' width='100%'>
            <Textinput
              label={t('globals.title')}
              propName={'title'}
              defaultValue={teacherInfo?.title || "Meet your teacher,"}
              onBlurValue={editTeacherInfo}
            />
            <Textinput
              label={t('globals.name')}
              propName={'name'}
              defaultValue={teacherInfo?.name}
              onBlurValue={editTeacherInfo}
            />
            <Textinput
              label={t('globals.about')}
              propName={'about'}
              defaultValue={teacherInfo?.about}
              onBlurValue={editTeacherInfo}
              isMultiline
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default EditTeacher;

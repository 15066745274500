import React from 'react';
import styled from 'styled-components';
import { drawerWidth } from '../../../../utils/constants';
import { theme } from '../../../../ui';
import { media } from '../../../../utils/media';
import TocMenuContent from './TocMenuContent';

const Wrapper = styled.div`
  width: ${drawerWidth}px;
  background-color: white;
  position: fixed;
  top: ${theme.headerSize};
  left: 0;
  height: calc(100vh - ${theme.headerSize});
  overflow: scroll;
  border-right: 1px solid ${ theme.colors.NEUTRAL_20};

  ${media.lessThan('tablet')} {
    display:none;
  }
`

const TocMenu = () => {
  return (
    <Wrapper>
      <TocMenuContent />
    </Wrapper>
  );
};

export default TocMenu;

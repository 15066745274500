import React from 'react';
import styled from 'styled-components'
import { Chip } from '@mui/material';
import theme from '../theme';

const ChipStyled = styled(Chip)`
  &.MuiChip-root{

    background-color:${theme.colors.SECONDARY_LIGHT};
    border-radius: 4px;
  }

  .MuiChip-label{
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${theme.colors.NEUTRAL_PRIMARY};
    font-weight: 600;
    font-size: ${(props) => props.fontSize || theme.fontSizes.xSmall};
  }
`

const NewBadge = ({label}) => {
  return (
    <ChipStyled label={label} size="small" />
  );
};

export default NewBadge;

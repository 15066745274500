import React from 'react';

import { FormControl,  FormGroup } from '@mui/material';
import CheckboxButton from '../CheckboxButton';

const CheckboxGroup = ({ items, localSetter, selectedCheckboxes, themeColor }) => {

  const handleChange = (value) => {
    localSetter(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(item => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  return (
    <FormControl fullWidth>
      <FormGroup>
        {items.map((option, index) => (
          <CheckboxButton
            value={index.toString()}
            key={index}
            labelText={option}
            checked={selectedCheckboxes?.includes(index)}
            handleChange={() => handleChange(index)}
            themeColor={themeColor}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup;

import { useMemo } from 'react';
import isEmpty from 'lodash.isempty';

const usePageNavigation = (toc, urlPageId) => {
  return useMemo(() => {
    if (isEmpty(toc)) return { prev: null, next: null };

    let activeSectionIndex, activePageIndex;
    const activeSection = toc.find((section, sectionIdx) => {
      activePageIndex = section.pages.findIndex((page) => page.id === Number(urlPageId));
      if (activePageIndex !== -1) {
        activeSectionIndex = sectionIdx;
        return true;
      }
      return false;
    });

    if (!activeSection) return { prev: null, next: null };

    const prev =
      activePageIndex === 0 && activeSectionIndex > 0
        ? toc[activeSectionIndex - 1].pages.at(-1)
        : activeSection.pages[activePageIndex - 1] || null;

    const next =
      activePageIndex === activeSection.pages.length - 1 && activeSectionIndex < toc.length - 1
        ? toc[activeSectionIndex + 1].pages[0]
        : activeSection.pages[activePageIndex + 1] || null;

    return { prev, next };
  }, [toc, urlPageId]);
}

export default usePageNavigation;

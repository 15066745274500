import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, MoreMenu } from '../../ui';
import { handleSort } from '../../helpers/sort';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';

const TableStyled = styled(({ fixed, ...props }) => <Table {...props} />)`
    thead, tbody{
      th, td{
        font-family:${(props) => props.theme.fonts.TEXT_FONT}, sans-serif;
        color:${(props) => props.theme.colors.NEUTRAL_PRIMARY};
        font-weight: 300;
        line-height: 18px;
        font-size: ${(props) => props.theme.fontSizes.small};
        padding: 12px 14px;
      }
    }
  ${({ fixed }) => !!fixed && `
    th:nth-child(1),
    td:nth-child(1) {
      width: 30%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 20%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 15%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 20%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 20%;
    }
  `}
`
const TableCellTitle = styled(TableCell)`
  text-transform: uppercase;
`

const TableRowStyled = styled(TableRow)`
  ${(props) => props.$hasClick === 'true' && `
      cursor: pointer;
      &:hover {
        background-color: ${props.theme.colors.NEUTRAL_LIGHT};
      }
  `}
`

const ListTable = ({ alignLeft = 0, tableInfo, data, onRowClick, tableName, fixed = false,
                     tableSetter, listDataProp, additionalData, defaultRowPerPage = 25 }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);
  const [editableData,  setEditableData] = useState([]);

  const clickRowHandler = useCallback((e, item) => {
    e.preventDefault()
    e.stopPropagation()
    onRowClick(item)
  }, [onRowClick])

  const onClick = useCallback((label, hasSorting, handleClick) => {
    setOrderBy(label);
    setOrder(order === 'desc' ? 'asc' : 'desc');
    const LocalOrder = order === 'desc' ? 'asc' : 'desc';

    if (hasSorting && !handleClick) {
      return handleSort({
        sortedArr: data,
        resultSetter: setEditableData,
        tableSetter,
        additionalData,
        listDataProp,
        sorting: hasSorting,
        order: LocalOrder,
      })
    }
    return handleClick && handleClick();

  }, [data, tableSetter, additionalData, listDataProp, order])

  useEffect(() => {
     tableInfo.forEach((cell) => {
      if (cell.hasOwnProperty('defaultSorting') && cell.defaultSorting) {
        return onClick(cell.label, cell.hasSorting, null);
      } else {
        setEditableData(data);
      }
    })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Flex direction='column' width='100%'>
      <TableContainer component={Paper}>
        <TableStyled sx={{ minWidth: 650 }} aria-label={tableName} fixed={fixed}>
          <TableHead>
            <TableRow>
              {tableInfo.map((headCell, i) =>
                <TableCellTitle
                  onClick={() => onClick(headCell.label, headCell?.hasSorting, headCell?.handleClick)}
                  key={`${tableName}-${headCell.label}`}
                  align={i === alignLeft ? 'left' : 'center'}
                >
                  {headCell?.hasSorting || headCell?.handleClick ? <TableSortLabel
                    active={orderBy === headCell.label}
                    direction={orderBy === headCell.label ? order : 'asc'}
                  >
                    {headCell.label}
                  </TableSortLabel> : headCell.label}
                </TableCellTitle>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {editableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <TableRowStyled
                // hover
                key={`${tableName}-${item.id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={(e) => onRowClick && clickRowHandler(e, item)}
                $hasClick={(!!onRowClick).toString()}
              >
                {tableInfo.map((row, index) => {
                  const menuOptions = !!row.menuOptions && row.menuOptions(item)
                  //this may be flawable
                  const rowData = !!row.data ?
                    row.data(item)
                    : menuOptions?.length
                      ? <MoreMenu
                        item={item}
                        key={item.id}
                        options={menuOptions}
                      />
                      : '-'
                  return <TableCell
                    key={`${tableName}-${row.label}- ${item.id}`}
                    component={index === 0 ? 'th' : ''}
                    scope={index === 0 ? 'row' : ''}
                    align={index === alignLeft ? 'left' : 'center'}>{rowData}</TableCell>
                })}
              </TableRowStyled>
            ))}
          </TableBody>
        </TableStyled>
      </TableContainer >
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Flex>
  );
};

export default ListTable;

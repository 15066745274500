import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit'
import { AddItem, Flex, Textinput, Typo, IconAsButton, Modal } from '../../../../ui';

const InfoPointHeader = styled.div`
  display:flex;
  align-items: center;
`

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  border-radius: 4px;
  padding: 15px;
  text-align: end;
  margin: 10px;
`

const InfoPointsGrid = styled.div`
  width:100%;
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;
  margin-bottom:2rem;
  
  /* Supports Grid */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-auto-rows: minmax(150px, auto);
  grid-gap: 1.875rem;
  
`

const BuildInfoPoints = ({open, info, addToPreview, isMini, onClose}) => {
  const { t } = useTranslation();

  const [infoPoints, setInfoPoints] = useState([])

  useEffect(() => {
    setInfoPoints(info)
  }, [info])

  const deletePoint = useCallback(id => {
    setInfoPoints(infoPoints.filter(el => el.id !== id));
  }, [infoPoints, setInfoPoints]);

  const addPoint = useCallback(() => {
    setInfoPoints([...infoPoints, { title: '', text: '', id: nanoid() }]);
  }, [infoPoints, setInfoPoints]);

  const editPointField = useCallback((e, id) => {
    const { value, dataset } = e.target
    const newPoint = [...infoPoints];
    newPoint.map(el => {
      if (el.id === id) {
        el[dataset.propname] = value;
      }
      return el;
    });
    setInfoPoints(newPoint);
  }, [infoPoints, setInfoPoints]);

  const onAccept = useCallback(() => {
    addToPreview(infoPoints)
  }, [infoPoints, addToPreview]);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel={t('webshop.addPreview')}
    >
    <Flex direction='column' width='100%' align='flex-start'>
      <Typo.ContentSmallHeader>{t('webshop.build.infoPointsTitle')}</Typo.ContentSmallHeader>
      <InfoPointsGrid>
        {infoPoints && infoPoints?.map((point, i) =>
          <Wrapper key={point.id || i}>
            <IconAsButton
              iconName='Delete'
              color={'#d01114'}
              tooltipLabel={t('globals.delete')}
              clickHandle={() => deletePoint(point.id)}
            />
            <InfoPointHeader>
              <Textinput
                label={t('globals.title')}
                propName={'title'}
                defaultValue={point?.title}
                onBlurValue={e => editPointField(e, point.id)}
              />
            </InfoPointHeader>
            <Textinput
              label={t('globals.description')}
              propName={'text'}
              defaultValue={point?.text}
              isMultiline
              onBlurValue={e => editPointField(e, point.id)}
            />
          </Wrapper>
        )}
        <AddItem text='point' handleClick={addPoint} />
      </InfoPointsGrid>
    </Flex>
    </Modal>
  );
};

export default BuildInfoPoints;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, RadioButtonGroup, RadioButton, theme } from '../../../../ui';

const SingleChoiceType = ({ results, data, onSubmit, themeColor}) => {
  const { t } = useTranslation();

  const [answer, setAnswer] = useState(null);
  const defaultValue = results.length > 0 && results.find(result => result.taskId === data.taskId)?.value[0];

  return (
    <Flex width='100%' gap='20' direction='column'>
      <RadioButtonGroup
        fullWidth={true}
        handleChange={e => setAnswer(Number(e.target.value))}
        selectedValue={answer || defaultValue}
      >
        {data.caption?.items?.map((option, index)=>
          <RadioButton value={index} labelText={option} key={index} themeColor={themeColor}/>
        )}
      </RadioButtonGroup>
      <Flex justify='flex-end' alignSelf='flex-end'>
        <Button $variant='customized' $bgcolor={themeColor || theme.colors.PRIMARY} onClick={() => onSubmit([answer])}>{t('globals.submit')}</Button>
      </Flex>
    </Flex>
  )
};

export default SingleChoiceType;

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { CONTENT_FULL, media } from '../../../utils/media';
import { Typo, SubPage, Flex, Button, theme } from '../../../ui';

import { saveLayout, selectLayout } from '../homeSlice';
import { selectStoreName } from '../globalSlice';
import { selectCourseById, selectCourses, selectHighlightedCoursesInfo } from '../coursesSlice';

import CoverMediaLayout from './components/CoverMediaLayout';
import BuildSuggestedCourse from './components/BuildSuggestedCourse';
import BuildSpotlightCourses from './components/BuildSpotlightCourses';

import BuildInfoIcons from '../components/InfoIconSection/BuildInfoIcons';
import BuildInfoPoints from '../components/InfoPointsSection/BuildInfoPoints';
import { List, ListItem, ListItemButton } from '@mui/material';
import CheckboxButton from '../../../ui/CheckboxButton';
import styled from 'styled-components';
import SettingsButton from './SettingsButton';
import ShopFrontLayout from '../ShopViewer/components/ShopFrontLayout';
import EditCtaBanner from './components/EditCtaBanner';
import EditIntroduction from './components/EditIntroduction';
import EditSectionTitles from './components/EditSectionTitles';

const PreviewContainer = styled.div`
  width:80%;
  border: 2px solid gray;
  height: calc(100vh - ${theme.headerSize} - 140px);
  overflow-y: scroll;

  ${media.lessThan('tablet')} {
    width:100%;
    height: 100%;
    overflow-y: visible;
    border: none;
  }

`
const Content = styled(Flex)`
  ${media.lessThan('tablet')} {
    flex-direction:column;
    align-items: stretch;
  }
`

export const cleanHighlightedCourses = (data) => {
  return data.map((item) => {
    return item.id
  })
}

const EditShopFrontLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const layoutStructure = useSelector(selectLayout);
  const courses = useSelector(selectCourses);
  const highlightedCoursesInfo = useSelector(selectHighlightedCoursesInfo);
  const storeName = useSelector(selectStoreName);
  const suggestedCourse = useSelector(state => selectCourseById(state, layoutStructure.suggestedCourse))

  const [layoutInfo, setLayoutInfo] = useState();

  const [openCoverMedia, setOpenCoverMedia] = useState(false);
  const [openInfoIcons, setOpenInfoIcons] = useState(false);
  const [openInfoPoints, setOpenInfoPoints] = useState(false);
  const [openCtaBanner, setOpenCtaBanner] = useState(false);
  const [openIntroduction, setOpenIntroduction] = useState(false);
  const [openHighlightedCourses, setOpenHighlightedCourses] = useState(false);
  const [openSuggestedCourse, setOpenSuggestedCourse] = useState(false);
  const [needToBeSaved, setNeedToBeSaved] = useState(false);
  const [openEditTitles, setOpenEditTitles] = useState(false);


  useEffect(() => {
    if (layoutStructure) {
      const tempLayout = cloneDeep(layoutStructure)
      tempLayout['activeModules'] = {
        introduction: !!tempLayout?.introduction && !!Object.keys(tempLayout?.introduction)?.length,
        infoIcons: !!tempLayout?.infoIcons && !!Object.keys(tempLayout?.infoIcons)?.length,
        infoPoints: !!tempLayout?.infoPoints && !!Object.keys(tempLayout?.infoPoints)?.length,
        coverMedia: !!tempLayout?.coverMedia?.image ||  !!tempLayout?.coverMedia?.video,
        highlightedCourses: !!highlightedCoursesInfo.length,
        suggestedCourse: !!suggestedCourse,
        ctaBanner: true,
      }
      tempLayout.highlightedCourses = highlightedCoursesInfo;

      setLayoutInfo(tempLayout)
    }
  }, [layoutStructure, highlightedCoursesInfo, suggestedCourse]);

  useEffect(() => {
    const needsUpdate = () => {
      const propertiesToCompare = [
        { propA: layoutInfo?.introduction, propB: layoutStructure.introduction },
        { propA: layoutInfo?.infoIcons, propB: layoutStructure.infoIcons },
        { propA: layoutInfo?.suggestedCourse, propB: suggestedCourse },
        { propA: layoutInfo?.infoPoints, propB: layoutStructure.infoPoints },
        { propA: layoutInfo?.highlightedCourses, propB: highlightedCoursesInfo },
        { propA: layoutInfo?.ctaBanner, propB: layoutStructure.ctaBanner },
        { propA: layoutInfo?.coverMedia, propB: layoutStructure.coverMedia },
        { propA: layoutInfo?.sectionTitles, propB: layoutStructure.sectionTitles },
      ];

      return propertiesToCompare.some(({ propA, propB }) => JSON.stringify(propA) !== JSON.stringify(propB));
    };

    setNeedToBeSaved(needsUpdate());
  }, [layoutInfo, layoutStructure, highlightedCoursesInfo, suggestedCourse]);


  const removeMockedIds = useCallback((data) => {
    return data.map(el => {
      if (el.hasOwnProperty('id')) {
        delete el.id;
      }
      return el;
    })
  }, [])

  const saveStructure = useCallback(() => {
    const { activeModules, coverMedia, introduction, infoPoints,
      infoIcons, suggestedCourse, highlightedCourses, ctaBanner, sectionTitles
    } = layoutInfo || {};

    const isActive = (module) => activeModules?.[module];
    const suggestedCourseId = typeof suggestedCourse === 'string' ? suggestedCourse : suggestedCourse?.id

    const newLayout = {
      coverMedia: isActive('coverMedia') ? coverMedia : null,
      introduction: isActive('introduction') ? introduction : null,
      infoPoints: isActive('infoPoints') ? removeMockedIds(infoPoints) : [],
      infoIcons: isActive('infoIcons') ? removeMockedIds(infoIcons) : [],
      suggestedCourse: isActive('suggestedCourse') ? suggestedCourseId : null,
      highlightedCourses: cleanHighlightedCourses(highlightedCourses),
      ctaBanner: isActive('ctaBanner') ? ctaBanner : null,
      sectionTitles: sectionTitles,
    };

    dispatch(saveLayout({ storeName, layoutData: newLayout }));
  }, [dispatch, storeName, removeMockedIds, layoutInfo]);


  const checkIfHasData = (target, info) => {
    if(target === 'coverMedia') return !!info?.image ||  !!info?.video
    else return !!info && (!!info.length || !!Object.keys(info)?.length)
  }

  const updatePreviewShowElement = (e, target, openModuleFnc) => {
    const checked = e.target.checked
    checked && !!openModuleFnc && !checkIfHasData(target, layoutInfo[target]) && openModuleFnc(true)
    setLayoutInfo({...layoutInfo, activeModules: {...layoutInfo.activeModules, [target]: checked}})
  }

  const addElementToPreview = (info, target, openModuleFnc) => {
    const hasData = checkIfHasData(target, info)
    setLayoutInfo({...layoutInfo, [target]: info, activeModules: {...layoutInfo.activeModules, [target]: hasData}})
    openModuleFnc && openModuleFnc(false)
  }

  const addToPreviewCtaBanner = (info) => {
    setLayoutInfo({...layoutInfo, ctaBanner: info})
    setOpenCtaBanner(false)
  }

  const MenuItem = ({labelText, checked, onChange, openModule, disabled}) =>
    <ListItem component="div" disablePadding>
      <ListItemButton disableGutters >
        { !disabled ? <CheckboxButton labelText={labelText}
          checked={checked}
          handleChange={onChange}
        /> : <Typo.Text>{labelText}</Typo.Text>}
      </ListItemButton>
      {!!openModule && <SettingsButton onClick={openModule}/>}
    </ListItem>

  return (
    <SubPage align='flex-start' gap='50' size={CONTENT_FULL} >
      <Content width='100%' align='flex-start' gap='20' justify='flex-start' direction='row' >
        {/* SETTINGS MENU */}
        <div>
          <List dense subheader={<Typo.TextTitle>{t('webshop.build.activateModules')}</Typo.TextTitle>}>
            <MenuItem
              labelText={t('webshop.home.editTitles.coverMedia')}
              checked={layoutInfo?.activeModules?.coverMedia}
              onChange={(e) => updatePreviewShowElement(e, 'coverMedia', setOpenCoverMedia)}
              openModule={() => setOpenCoverMedia(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.ctaBanner')}
              checked={true}
              disabled={true}
              onChange={(e) => updatePreviewShowElement(e, 'ctaBanner', setOpenCtaBanner)}
              openModule={() => setOpenCtaBanner(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.informationPointsIcons')}
              checked={layoutInfo?.activeModules?.infoIcons}
              onChange={(e) => updatePreviewShowElement(e, 'infoIcons', setOpenInfoIcons)}
              openModule={() => setOpenInfoIcons(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.introductionToStore')}
              checked={layoutInfo?.activeModules?.introduction}
              onChange={(e) => updatePreviewShowElement(e, 'introduction', setOpenIntroduction)}
              openModule={() => setOpenIntroduction(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.spotlightedCourses')}
              checked={layoutInfo?.activeModules?.highlightedCourses}
              onChange={(e) => updatePreviewShowElement(e, 'highlightedCourses', setOpenHighlightedCourses)}
              openModule={() => setOpenHighlightedCourses(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.infoPointsTitle')}
              checked={layoutInfo?.activeModules?.infoPoints}
              onChange={(e) => updatePreviewShowElement(e, 'infoPoints', setOpenInfoPoints)}
              openModule={() => setOpenInfoPoints(true)}
            />

            <MenuItem
              labelText={t('webshop.home.editTitles.promoteCourse')}
              checked={layoutInfo?.activeModules?.suggestedCourse}
              onChange={(e) => updatePreviewShowElement(e, 'suggestedCourse', setOpenSuggestedCourse)}
              openModule={() => setOpenSuggestedCourse(true)}
            />

            <MenuItem
              labelText={t('webshop.editSectionTitles')}
              openModule={()=> setOpenEditTitles(true)}
              disabled={true}
            />

          </List>
          <Button icon='Save' onClick={saveStructure} disabled={!needToBeSaved} >{t('globals.save')}</Button>

        </div>

        {/* PREVIEW */}
        <PreviewContainer>
          <ShopFrontLayout
            preview={true}
            layoutStructure={layoutInfo}
            {...layoutInfo}
          />
        </PreviewContainer>

      </Content>

      <CoverMediaLayout
        open={openCoverMedia}
        info={layoutInfo?.coverMedia}
        addToPreview={(info) => addElementToPreview(info, 'coverMedia', setOpenCoverMedia)}
        onClose={() => {
          addElementToPreview(layoutInfo.coverMedia, 'coverMedia')
          setOpenCoverMedia(false)
        }}
      />

      <EditCtaBanner
        open={openCtaBanner}
        info={layoutInfo?.ctaBanner}
        onClose={() => {
          addElementToPreview(layoutInfo.ctaBanner, 'ctaBanner')
          setOpenCtaBanner(false)
        }}
        addToPreview={addToPreviewCtaBanner}
      />

      <BuildInfoIcons
        open={openInfoIcons}
        info={layoutInfo?.infoIcons}
        onClose={() => {
          addElementToPreview(layoutInfo.infoIcons, 'infoIcons')
          setOpenInfoIcons(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'infoIcons', setOpenInfoIcons)}
      />

      <BuildInfoPoints
        open={openInfoPoints}
        info={layoutInfo?.infoPoints}
        onClose={() => {
          addElementToPreview(layoutInfo.infoPoints, 'infoPoints')
          setOpenInfoPoints(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'infoPoints', setOpenInfoPoints)}

      />
      <EditIntroduction
        open={openIntroduction}
        info={layoutInfo?.introduction}
        onClose={() => {
          addElementToPreview(layoutInfo.introduction, 'introduction')
          setOpenIntroduction(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'introduction', setOpenIntroduction)}
      />

      <BuildSpotlightCourses
        allCourses={courses}
        open={openHighlightedCourses}
        info={layoutInfo?.highlightedCourses}
        onClose={() => {
          addElementToPreview(layoutInfo.highlightedCourses, 'highlightedCourses')
          setOpenHighlightedCourses(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'highlightedCourses', setOpenHighlightedCourses)}
      />

      <BuildSuggestedCourse
        allCourses={courses}
        open={openSuggestedCourse}
        selectedCourse={suggestedCourse}
        onClose={() => {
          addElementToPreview(layoutInfo.suggestedCourse, 'suggestedCourse')
          setOpenSuggestedCourse(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'suggestedCourse', setOpenSuggestedCourse)}
      />

      <EditSectionTitles
        context='home'
        open={openEditTitles}
        info={layoutInfo?.sectionTitles}
        onClose={() => {
          addElementToPreview(layoutInfo.sectionTitles, 'sectionTitles')
          setOpenEditTitles(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'sectionTitles', setOpenEditTitles)}
      />

    </SubPage>
  );
};

export default EditShopFrontLayout;

import React from 'react';
import { Typo } from '../../../../ui';
import styled from 'styled-components';
import { media } from '../../../../utils/media';

const Container = styled.div`
    min-height:200px;
    width:100%;
    margin:0;
    padding-top:30px;
    overflow: scroll;
    
    ${media.lessThan('bigTablet')} {
      border-radius: 15px;
      background-color: white;
    }
`

const NotesContent = () => {
  return (
    <Container>
        <Typo.ContentBigHeader>Make a note</Typo.ContentBigHeader>
    </Container>
  );
}

export default NotesContent;
